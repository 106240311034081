import {Component, Inject, OnInit} from '@angular/core';
import {FuseTranslationLoaderService} from '../../../../../@fuse/services/translation-loader.service';
import {locale as polish} from '../../../../translations/pl';
import {locale as english} from '../../../../translations/en';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {RoleTypes, User, UserAclGroups, UserTypes} from '../../../../shared/models/users.model';
import {UsersService} from '../../../../services/users.service';
import {TranslateService} from '@ngx-translate/core';
import {PasswordValidator} from '../../../../shared/validators/password.validator';
import {map, switchMap} from 'rxjs/operators';
import {ConfirmModalComponent} from '../../../shared/components/confirm-modal/confirm-modal.component';
import {CompanyBranch} from '../../../../shared/models/company.model';
import {AuthService} from '../../../../services/auth.service';
import {markFormGroupTouched} from "../../../../shared/utils/markFormGroupAsTouched";

@Component({
  selector: 'user-editor',
  templateUrl: './user-editor.component.html',
  styleUrls: ['./user-editor.component.scss']
})
export class UserEditorComponent implements OnInit {
  public userForm: FormGroup;
  public editedUserUuid: User['uuid'];
  public editedUser;
  public loggedUser;
  public mode: string = 'view';
  public userTypes = UserTypes;
  public aclGroups: UserAclGroups;
  public companyBranches: CompanyBranch[] = [];
  public passwordForm: FormGroup;
  public changePassword = false;
  public isLoading = false;
  public isAdmin = false;

  constructor(public userEditorDialogRef: MatDialogRef<UserEditorComponent>,
              @Inject(MAT_DIALOG_DATA) private data: any,
              private matDialog: MatDialog,
              private usersService: UsersService,
              private authService: AuthService,
              private translateService: TranslateService,
              private snackBar: MatSnackBar,
              private formBuilder: FormBuilder,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
    this.mode = data.mode;
    this.editedUserUuid = data.editedUserUuid;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);

    this.loggedUser = this.authService.getUser();
    if (this.loggedUser.roleType === RoleTypes.Admin) {
      this.isAdmin = true;
    }
  }

  ngOnInit() {
    this.createForm({});

    this.usersService.getAclGroupsList().subscribe(
      res => {
        this.aclGroups = res;
      }
    );

    if (this.mode === 'edit' || this.mode === 'view') {
      this.usersService.getUser(this.editedUserUuid).pipe(
        map(res => {
          this.editedUser = res;
        }),
        switchMap(() => this.usersService.getCompanyBranchList())
      ).subscribe(res => {
        this.companyBranches = res.branches;
        this.createForm(this.editedUser);
      });
    } else {
      this.usersService.getCompanyBranchList().subscribe(
        res => {
          this.companyBranches = res.branches;
        }
      );
    }
  }

  public submitUser() {
    this.isLoading = true;
    markFormGroupTouched(this.userForm);
    if (this.userForm.invalid) {
      this.isLoading = false;
      return;
    }
    const userType = this.userForm.get('userType').value;
    const roleType = this.userForm.get('roleType').value;
    const aclGroupId = this.aclGroups[userType].find(item => item.roleType === roleType).uuid;
    this.userForm.addControl('aclGroupId', new FormControl(aclGroupId, Validators.required));

    if (this.mode === 'add') {
      this.usersService.createUser(this.userForm.value).subscribe(
        () => {
          this.userEditorDialogRef.close();

          this.userEditorDialogRef.afterClosed().subscribe(() => {
            this.usersService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('USERS.MESSAGES.CREATE_SUCCESS'), '', {duration: 5000});
        }, () => {
          this.isLoading = false;
          this.snackBar.open(this.translateService.instant('USERS.MESSAGES.CREATE_ERROR'), '', {duration: 5000});
        }
      )
    } else {
      if (this.changePassword) {
        this.userForm.patchValue({password: this.passwordForm.get('password').value})
      }

      this.usersService.updateUser(this.editedUserUuid, this.userForm.value).subscribe(
        () => {
          this.userEditorDialogRef.close();

          this.userEditorDialogRef.afterClosed().subscribe(() => {
            this.usersService.onEditEvent.emit();
          });

          this.snackBar.open(this.translateService.instant('USERS.MESSAGES.UPDATE_SUCCESS'), '', {duration: 5000});
        }, () => {
          this.isLoading = false;
          this.snackBar.open(this.translateService.instant('USERS.MESSAGES.UPDATE_ERROR'), '', {duration: 5000});
        }
      );
    }
  }

  public deleteUser() {
    let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });

    deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant('USERS.MESSAGES.DELETE_QUESTION');
    deleteDialogRef.componentInstance.titleMessage = this.translateService.instant('USERS.MESSAGES.DELETE_USER_TITLE');
    deleteDialogRef.componentInstance.confirmButton = this.translateService.instant('USERS.DELETE');

    deleteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.usersService.deleteUser(this.editedUserUuid).subscribe(
          () => {
            this.userEditorDialogRef.close();
            this.userEditorDialogRef.afterClosed().subscribe(() => {
              this.usersService.onEditEvent.emit();
            });
            this.snackBar.open(this.translateService.instant('USERS.MESSAGES.DELETE_SUCCESS'), '', {duration: 5000});
          }, () => {
            this.snackBar.open(this.translateService.instant('USERS.MESSAGES.DELETE_ERROR'), '', {duration: 5000});
          }
        )
      }
    });
  }

  public enableEditMode() {
    this.userEditorDialogRef.close();
    this.matDialog.open(UserEditorComponent, {
      panelClass: 'user-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        editedUserUuid: this.editedUserUuid
      }
    });
    this.userForm.get('email').disable();
    this.userForm.get('userType').disable();
  }

  public toggleChangePassword() {
    this.changePassword = !this.changePassword;

    if (this.changePassword) {
      this.passwordForm = this.formBuilder.group({
        password: new FormControl('', [Validators.required, Validators.minLength(5)]),
        confirmPassword: new FormControl('', Validators.required)
      }, {
        validator: PasswordValidator.MatchPassword
      })
    } else {
      this.passwordForm = null;
      this.userForm.get('password').reset();
    }
  }

  public getTitle(): string {
    switch (this.mode) {
      case 'add':
        return this.translateService.instant('USERS.TITLE_ADD');
      case 'edit':
        return this.translateService.instant('USERS.TITLE_EDIT');
      case 'view':
        return this.translateService.instant('USERS.TITLE_VIEW');
      default:
        return;
    }
  }

  private createForm(inputData: User | any) {
    this.userForm = this.formBuilder.group({
      name: new FormControl(inputData.name || '', Validators.required),
      surname: new FormControl(inputData.surname || '', Validators.required),
      email: new FormControl(inputData.email || '', [Validators.required, Validators.email]),
      roleType: new FormControl(inputData.roleType || '', Validators.required),
      userType: new FormControl(inputData.userType || '', Validators.required),
      companyBranchId: new FormControl(inputData.companyBranchId || '', Validators.required),
      password: new FormControl(null),
    });

    if (this.mode !== 'add') {
      this.userForm.addControl('active', new FormControl(inputData.active || false, Validators.required))
    }

    if (this.mode === 'view') {
      this.userForm.disable();
    }
  }

}
