import { NgModule } from '@angular/core';
import { CompaniesContainerComponent } from './companies.container.component';
import { CompaniesListComponent } from './components/companies-list/companies-list.component';
import { CompanyEditorComponent } from './components/company-editor/company-editor.component';
import { Routes } from './companies.routes';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CompanyAccountsListComponent } from './components/company-editor/company-accounts-list/company-accounts-list.component';
import { CompanyAccountsModule } from '../company-accounts/company-accounts.module';
import { CompanyBranchesListComponent } from './components/company-editor/company-branches-list/company-branches-list.component';
import { CompanyCarsListComponent } from './components/company-editor/company-cars-list/company-cars-list.component';
import { CompanyBranchesModule } from '../company-branches/company-branches.module';
import { CompanyCarsModule } from '../company-cars/company-cars.module';

@NgModule({
  declarations: [
    CompaniesContainerComponent,
    CompaniesListComponent,
    CompanyEditorComponent,
    CompanyAccountsListComponent,
    CompanyBranchesListComponent,
    CompanyCarsListComponent
  ],
  imports: [
    RouterModule.forChild(Routes),
    SharedModule,
    CompanyAccountsModule,
    CompanyBranchesModule,
    CompanyCarsModule
  ],
  exports: [
    CompaniesListComponent,
    CompanyEditorComponent,
  ],
  entryComponents: [
    CompanyEditorComponent,
  ]
})
export class CompaniesModule { }
