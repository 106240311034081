var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { OrderReceiving, OrderSatuses } from '../../../../../shared/models/orders.model';
import { OrdersService } from '../../../../../services/orders.service';
import { locale as polish } from '../../../../../translations/pl';
import { locale as english } from '../../../../../translations/en';
import { ConfirmModalComponent } from '../../../../shared/components/confirm-modal/confirm-modal.component';
import { AuthService } from '../../../../../services/auth.service';
import { RoleTypes, UserTypes } from '../../../../../shared/models/users.model';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { NEVER, of, throwError, zip } from 'rxjs';
import { UsersService } from '../../../../../services/users.service';
import * as moment from 'moment';
import { environment } from '../../../../../../environments/environment';
import { ValidateItemsList } from '../../../../../shared/validators/itemsList.validator';
import { FilteredListsService } from '../../../../../services/filtered-lists.service';
import { OrderTypes } from '../../../../../shared/consts/orders';
import { mapOrderDataApiValues, mapOrderDataToUuid } from '../../../../../shared/utils/tasks-map';
import { TaskReceivingFormComponent } from '../../../../tasks/components/task-forms/task-receiving-form/task-receiving-form.component';
import { HistoryDialogComponent } from '../../../../shared/components/history-dialog/history-dialog.component';
import { ItemsOrigins } from '../../../../../shared/models/history.model';
import { EndTimeOptionsReceiving, StartTimeOptionsReceiving } from '../../../../../shared/consts/tasks';
import { CompanyEditorComponent } from '../../../../companies/components/company-editor/company-editor.component';
import { CompanyBranchesService } from '../../../../../services/company-branches.service';
import { CompaniesService } from '../../../../../services/companies.service';
import { markFormGroupTouched } from 'app/shared/utils/markFormGroupAsTouched';
var OrderReceivingFormComponent = /** @class */ (function () {
    function OrderReceivingFormComponent(orderEditorDialogRef, data, matDialog, authService, ordersService, filteredListsService, usersService, companyBranchesService, companiesService, translateService, snackBar, formBuilder, _fuseTranslationLoaderService) {
        var _this = this;
        this.orderEditorDialogRef = orderEditorDialogRef;
        this.data = data;
        this.matDialog = matDialog;
        this.authService = authService;
        this.ordersService = ordersService;
        this.filteredListsService = filteredListsService;
        this.usersService = usersService;
        this.companyBranchesService = companyBranchesService;
        this.companiesService = companiesService;
        this.translateService = translateService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.orderStatuses = OrderSatuses;
        this.files = [];
        this.newFiles = [];
        this.userTypes = UserTypes;
        this.roleTypes = RoleTypes;
        this.canUserEdit = false;
        this.canCancelOrder = false;
        this.canGenerateTask = false;
        this.canViewTask = false;
        this.customerCompanyOptions = [];
        this.companyBranches = [];
        this.customerCompanyBranches = [];
        this.isLoading = false;
        this.sizeError = false;
        this.typeError = false;
        this.startTimeOptionsReceiving = StartTimeOptionsReceiving;
        this.endTimeOptionsReceiving = EndTimeOptionsReceiving;
        this.filterParams = {
            page: 1,
            limit: 20,
            sort: 'name',
            order: 'asc'
        };
        this.availableHoursFromDisabled = function (i, timeIndex) {
            return _this.orderReceivingForm.get('availableHoursTo').value &&
                _this.startTimeOptionsReceiving.includes(_this.orderReceivingForm.get('availableHoursTo').value) &&
                timeIndex + 1 > _this.startTimeOptionsReceiving.indexOf(_this.orderReceivingForm.get('availableHoursTo').value);
        };
        this.availableHoursToDisabled = function (i, timeIndex) {
            return _this.orderReceivingForm.get('availableHoursFrom').value &&
                timeIndex < _this.startTimeOptionsReceiving.indexOf(_this.orderReceivingForm.get('availableHoursFrom').value);
        };
        this.isCloseDialogOpen = false; // without this, you can open the dialog multiple times
        this.mode = data.mode;
        this.orderUuid = data.orderUuid;
        this.user = this.authService.user.user;
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
    }
    OrderReceivingFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm();
        this.createSearchFilters();
        if (this.mode === 'edit' || this.mode === 'view' || this.mode === 'clone') {
            this.ordersService
                .getOrder(this.orderUuid)
                .pipe(map(function (res) {
                _this.order = res;
                _this.files = res.files;
                _this.canCancelOrder =
                    _this.order &&
                        _this.order.status !== _this.orderStatuses.Cancelled &&
                        (_this.user.userType === _this.userTypes.Sales ||
                            _this.user.userType === _this.userTypes.Logistician ||
                            _this.user.uuid === _this.order.createdBy.uuid);
                _this.canUserEdit =
                    _this.order &&
                        !_this.order.taskNumber &&
                        (_this.user.userType === _this.userTypes.Sales ||
                            _this.user.userType === _this.userTypes.Logistician ||
                            _this.user.uuid === _this.order.createdBy.uuid);
                _this.canGenerateTask =
                    _this.order &&
                        _this.order.status !== _this.orderStatuses.Cancelled &&
                        !_this.order.taskNumber &&
                        _this.user.userType === _this.userTypes.Logistician;
                _this.canViewTask =
                    _this.order &&
                        _this.order.taskNumber &&
                        _this.user.userType === _this.userTypes.Logistician;
            }), switchMap(function () {
                return zip(_this.filteredListsService.getCompaniesList(_this.filterParams), _this.usersService.getCompanyBranchList(), _this.companiesService.getCompany(_this.order.customerCompany.uuid));
            }))
                .subscribe(function (_a) {
                var companiesRes = _a[0], branchesRes = _a[1], company = _a[2];
                _this.customerCompanyOptions = companiesRes.customerCompanies;
                _this.companyBranches = branchesRes.branches;
                _this.bdo = company.bdo;
                _this.createForm(_this.order);
                _this.checkCustomerCompaniesMissing();
                _this.getCustomerCompanyBranches(_this.order.customerCompany.uuid);
            });
        }
        else {
            this.todayDate = new Date();
            this.filteredListsService
                .getCompaniesList(this.filterParams)
                .subscribe(function (res) { return (_this.customerCompanyOptions = res.customerCompanies); });
            this.usersService
                .getCompanyBranchList()
                .subscribe(function (res) { return (_this.companyBranches = res.branches); });
        }
    };
    OrderReceivingFormComponent.prototype.submitOrder = function () {
        var _this = this;
        this.isLoading = true;
        markFormGroupTouched(this.orderReceivingForm);
        if (this.orderReceivingForm.invalid) {
            this.isLoading = false;
            return;
        }
        var newOrder = JSON.parse(JSON.stringify(this.orderReceivingForm.getRawValue()));
        if (newOrder.suggestedDate) {
            newOrder.suggestedDate = moment(newOrder.suggestedDate).format('YYYY-MM-DD');
        }
        newOrder.bdo = newOrder.bdo ? newOrder.bdo.toString() : null;
        newOrder.bdoFree = Boolean(newOrder.bdoFree && !newOrder.bdo);
        newOrder.bdoPeopleCollection = Boolean(newOrder.bdoPeopleCollection && !newOrder.bdo);
        newOrder.customerCompanyId = newOrder.customerCompany
            ? newOrder.customerCompany.uuid
            : null;
        var CCBranchId = newOrder.customerCompanyBranch;
        newOrder.customerCompanyBranch = __assign({}, newOrder.customerCompanyBranchOther, { uuid: CCBranchId !== 'other' ? CCBranchId : null });
        newOrder.customerCompanyBranch.email = newOrder.customerCompanyBranch.email || null;
        newOrder.price = Number(String(newOrder.price).replace(',', '.')) || null;
        delete newOrder.customerCompanyBranchOther;
        delete newOrder.customerCompany;
        newOrder.orderData = mapOrderDataApiValues(newOrder.orderData);
        if (this.mode === 'add' || this.mode === 'clone') {
            this.ordersService
                .createOrder(newOrder)
                .pipe(catchError(function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CREATE_ERROR'), '', { duration: 5000 });
                return NEVER;
            }), mergeMap(function (res) {
                if (_this.newFiles) {
                    _this.addFiles(res.uuid);
                }
                return of({});
            }))
                .subscribe(function (filesSuccess) {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                if (filesSuccess) {
                    _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CREATE_SUCCESS'), '', { duration: 5000 });
                }
                else {
                    _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CREATE_SUCCESS_FILES_ERROR'), '', { duration: 5000 });
                }
            });
        }
        else if (this.mode === 'view' && !this.canUserEdit) {
            this.ordersService.updateOrderDate(this.orderUuid, newOrder.suggestedDate).subscribe(function () {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.UPDATE_SUCCESS'), '', { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.UPDATE_ERROR'), '', { duration: 5000 });
            });
        }
        else {
            this.ordersService.updateOrder(this.orderUuid, newOrder).subscribe(function () {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.UPDATE_SUCCESS'), '', { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.UPDATE_ERROR'), '', { duration: 5000 });
            });
        }
    };
    OrderReceivingFormComponent.prototype.openImage = function (url) {
        window.open(url);
    };
    OrderReceivingFormComponent.prototype.deleteOrder = function () {
        var _this = this;
        var changeStatusDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false
        });
        changeStatusDialogRef.componentInstance.confirmMessage = this.translateService.instant('ORDERS.MESSAGES.DELETE_QUESTION');
        changeStatusDialogRef.componentInstance.titleMessage = this.translateService.instant('ORDERS.MESSAGES.DELETE_ORDER_TITLE');
        changeStatusDialogRef.componentInstance.confirmButton = this.translateService.instant('ORDERS.DELETE');
        changeStatusDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.ordersService.deleteOrder(_this.orderUuid).subscribe(function () {
                    _this.orderEditorDialogRef.close();
                    _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                        _this.ordersService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.DELETE_SUCCESS'), '', { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.DELETE_ERROR'), '', { duration: 5000 });
                });
            }
        });
    };
    OrderReceivingFormComponent.prototype.generateTask = function () {
        if (this.order.companyBranch !== null && this.order.salesAccepted) {
            this.orderEditorDialogRef.close();
            this.matDialog.open(TaskReceivingFormComponent, {
                panelClass: 'task-editor-dialog',
                autoFocus: false,
                disableClose: true,
                data: {
                    mode: 'add',
                    orders: [this.order]
                }
            });
        }
        else {
            this.snackBar.open(this.translateService.instant('ORDERS.MESSAGES.GENERATE_ERROR'), '', { duration: 5000 });
        }
    };
    OrderReceivingFormComponent.prototype.viewTask = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(TaskReceivingFormComponent, {
            panelClass: 'task-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'view',
                taskNumber: this.order.taskNumber
            }
        });
    };
    OrderReceivingFormComponent.prototype.changeOrderStatus = function (status) {
        var _this = this;
        var changeStatusDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false
        });
        if (status === this.orderStatuses.Planned) {
            changeStatusDialogRef.componentInstance.confirmMessage = this.translateService.instant('ORDERS.MESSAGES.PLAN_QUESTION');
            changeStatusDialogRef.componentInstance.titleMessage = this.translateService.instant('ORDERS.MESSAGES.PLAN_ORDER_TITLE');
            changeStatusDialogRef.componentInstance.confirmButton = this.translateService.instant('ORDERS.PLAN_ORDER');
            changeStatusDialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.ordersService
                        .changeOrderStatus(_this.orderUuid, _this.orderStatuses.Planned)
                        .subscribe(function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.PLAN_SUCCESS'), '', { duration: 5000 });
                        _this.order.status = _this.orderStatuses.Planned;
                        _this.ordersService.onEditEvent.emit();
                    }, function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.PLAN_ERROR'), '', { duration: 5000 });
                    });
                }
            });
        }
        else {
            changeStatusDialogRef.componentInstance.confirmMessage = this.translateService.instant('ORDERS.MESSAGES.CANCEL_QUESTION');
            changeStatusDialogRef.componentInstance.titleMessage = this.translateService.instant('ORDERS.MESSAGES.CANCEL_ORDER_TITLE');
            changeStatusDialogRef.componentInstance.confirmButton = this.translateService.instant('ORDERS.CANCEL_ORDER');
            changeStatusDialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.ordersService
                        .changeOrderStatus(_this.orderUuid, _this.orderStatuses.Cancelled)
                        .subscribe(function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CANCEL_SUCCESS'), '', { duration: 5000 });
                        _this.order.status = _this.orderStatuses.Cancelled;
                        _this.canCancelOrder = false;
                        _this.ordersService.onEditEvent.emit();
                        _this.orderEditorDialogRef.close();
                    }, function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CANCEL_ERROR'), '', { duration: 5000 });
                    });
                }
            });
        }
    };
    OrderReceivingFormComponent.prototype.setCustomerCompany = function (customerCompany) {
        return __awaiter(this, void 0, void 0, function () {
            var ccMainBranch;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(customerCompany && customerCompany.uuid)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getCustomerCompanyBranches(customerCompany.uuid)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        ccMainBranch = this.customerCompanyBranches[0];
                        this.orderReceivingForm.get('customerCompanyBranch').enable();
                        this.orderReceivingForm.patchValue({
                            customerCompany: customerCompany,
                            customerCompanyBranch: ccMainBranch ? ccMainBranch.uuid || 'other' : null,
                            customerCompanyBranchOther: {
                                street: (ccMainBranch && ccMainBranch.street) || null,
                                postalCode: (ccMainBranch && ccMainBranch.postalCode) || null,
                                city: (ccMainBranch && ccMainBranch.city) || null,
                                country: (ccMainBranch && ccMainBranch.country) || 'Polska',
                                contact: (ccMainBranch && ccMainBranch.contact) || null,
                                email: (ccMainBranch && ccMainBranch.email) || null,
                                phone: (ccMainBranch && ccMainBranch.phone) || null,
                                note: (ccMainBranch && ccMainBranch.note) || null,
                                mainBranch: false
                            }
                        });
                        if (customerCompany && customerCompany.trusted) {
                            this.orderReceivingForm.get('salesAccepted').patchValue(true);
                        }
                        else {
                            this.orderReceivingForm.get('salesAccepted').patchValue(false);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderReceivingFormComponent.prototype.compareObjectsByUuid = function (val1, val2) {
        return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
    };
    OrderReceivingFormComponent.prototype.getTitle = function () {
        switch (this.mode) {
            case 'add':
            case 'clone':
                return this.translateService.instant('ORDERS.TITLE_ADD');
            case 'edit':
                return this.translateService.instant('ORDERS.TITLE_EDIT');
            case 'view':
                return this.translateService.instant('ORDERS.TITLE_VIEW');
            default:
                return;
        }
    };
    OrderReceivingFormComponent.prototype.showHistory = function () {
        this.matDialog.open(HistoryDialogComponent, {
            panelClass: 'history-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                item: this.order,
                itemOrigin: ItemsOrigins.Order,
                itemId: this.orderUuid,
                itemNumber: this.order.orderNumber
            }
        });
    };
    OrderReceivingFormComponent.prototype.enableEditMode = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(OrderReceivingFormComponent, {
            panelClass: 'order-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'edit',
                orderUuid: this.orderUuid
            }
        });
    };
    OrderReceivingFormComponent.prototype.enableCloneMode = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(OrderReceivingFormComponent, {
            panelClass: 'order-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'clone',
                orderUuid: this.orderUuid
            }
        });
    };
    // FILES
    OrderReceivingFormComponent.prototype.openFile = function () {
        this.fileInput.nativeElement.click();
    };
    OrderReceivingFormComponent.prototype.dropFile = function (event) {
        var _this = this;
        event.files[0].fileEntry['file'](function (fileData) {
            _this.uploadFile(fileData);
        });
    };
    OrderReceivingFormComponent.prototype.addFile = function (event) {
        if (event.target.files && event.target.files.length > 0) {
            var file = event.target.files[0];
            this.uploadFile(file);
        }
    };
    OrderReceivingFormComponent.prototype.deleteFile = function (event) {
        var _this = this;
        if (this.mode === 'add') {
            this.newFiles.splice(event, 1);
        }
        else {
            var deleteDialogRef_1 = this.matDialog.open(ConfirmModalComponent, {
                disableClose: false,
                autoFocus: false
            });
            deleteDialogRef_1.componentInstance.confirmMessage = this.translateService.instant('ORDERS.MESSAGES.DELETE_FILE_QUESTION');
            deleteDialogRef_1.componentInstance.titleMessage = this.translateService.instant('ORDERS.MESSAGES.DELETE_FILE_TITLE');
            deleteDialogRef_1.componentInstance.confirmButton = this.translateService.instant('ORDERS.DELETE');
            deleteDialogRef_1.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.ordersService.deleteFile(_this.orderUuid, event).subscribe(function () {
                        deleteDialogRef_1.close();
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.DELETE_FILE_SUCCESS'), '', { duration: 5000 });
                        _this.files = _this.files.filter(function (item) { return item.fileId !== event; });
                    }, function () {
                        deleteDialogRef_1.close();
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.DELETE_FILE_ERROR'), '', { duration: 5000 });
                    });
                }
            });
        }
    };
    OrderReceivingFormComponent.prototype.addCustomerCompany = function () {
        var _this = this;
        var editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
            panelClass: 'company-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add'
            }
        });
        editorDialogRef.componentInstance.onAddCompany.subscribe(function (res) {
            _this.customerCompanyOptions.push(res);
            _this.setCustomerCompany(res);
            _this.companySelect.close();
        });
    };
    OrderReceivingFormComponent.prototype.openEmailClient = function () {
        var orderData = this.orderReceivingForm.getRawValue();
        if (orderData.customerCompanyBranchOther.email) {
            return "mailto:" + orderData.customerCompanyBranchOther.email;
        }
    };
    OrderReceivingFormComponent.prototype.canSendEmail = function () {
        return this.mode === 'view' && this.orderReceivingForm.getRawValue().customerCompanyBranchOther.email;
    };
    OrderReceivingFormComponent.prototype.uploadFile = function (file) {
        var _this = this;
        var fileItem;
        var allowedExtentions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'pdf'];
        var extention = file.name
            .split('.')
            .pop()
            .toLowerCase();
        if (!allowedExtentions.includes(extention)) {
            this.sizeError = false;
            return (this.typeError = true);
        }
        else if (file.size > 5242880) {
            this.typeError = false;
            return (this.sizeError = true);
        }
        else {
            this.sizeError = false;
            this.typeError = false;
            var reader_1 = new FileReader();
            reader_1.readAsDataURL(file);
            reader_1.onload = function () {
                fileItem = { data: reader_1.result, name: file.name, type: file.type };
                if (_this.mode === 'add') {
                    _this.newFiles.push(fileItem);
                }
                else {
                    _this.ordersService.addFile(_this.orderUuid, fileItem).subscribe(function (res) {
                        res.url = environment.apiUrl + "/" + _this.authService.getUserType() + "/orders/" + _this.orderUuid + "/files/" + res.fileId;
                        _this.files.push(res);
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.ADD_FILE_SUCCESS'), '', { duration: 5000 });
                    }, function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.ADD_FILE_ERROR'), '', { duration: 5000 });
                    });
                }
            };
        }
    };
    OrderReceivingFormComponent.prototype.addFiles = function (orderId) {
        var _this = this;
        var filesSuccess = true;
        this.newFiles.forEach(function (file) {
            return _this.ordersService
                .addFile(orderId, file)
                .pipe(catchError(function (err) {
                _this.isLoading = false;
                filesSuccess = false;
                return throwError(err);
            }))
                .subscribe(function () { return filesSuccess; });
        });
    };
    OrderReceivingFormComponent.prototype.getCustomerCompanyBranches = function (customerCompanyId) {
        return __awaiter(this, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.companyBranchesService
                                .getCompanyBranchesList({ limit: 500, page: 0 }, customerCompanyId).toPromise()];
                    case 1:
                        res = _a.sent();
                        this.customerCompanyBranches = res.branches;
                        return [2 /*return*/, true];
                    case 2:
                        e_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderReceivingFormComponent.prototype.createForm = function (inputData) {
        var _this = this;
        if (inputData === void 0) { inputData = new OrderReceiving(); }
        inputData.orderData = mapOrderDataToUuid(inputData.orderData);
        this.orderReceivingForm = this.formBuilder.group({
            customerCompany: new FormControl(inputData.customerCompany || null, Validators.required),
            salesAccepted: new FormControl(inputData.salesAccepted || false, Validators.required),
            companyBranchId: new FormControl(inputData.companyBranch ? inputData.companyBranch.uuid : null, Validators.required),
            customerCompanyBranch: new FormControl(inputData.customerCompanyBranch
                ? inputData.customerCompanyBranch.uuid
                    ? inputData.customerCompanyBranch.uuid
                    : 'other'
                : null, Validators.required),
            orderData: new FormControl(inputData.orderData || [], ValidateItemsList),
            suggestedDate: new FormControl(this.mode === 'clone'
                ? null
                : inputData.suggestedDate
                    ? new Date(inputData.suggestedDate)
                    : null),
            availableHoursFrom: new FormControl(inputData.availableHoursFrom || null),
            availableHoursTo: new FormControl(inputData.availableHoursTo || null),
            tags: new FormControl(inputData.tags || []),
            comment: new FormControl(this.mode === 'clone' ? null : inputData.comment || null),
            bdo: new FormControl(inputData.bdo || null, Validators.pattern('[0-9]+')),
            bdoFree: new FormControl(inputData.bdoFree && !inputData.bdo && this.mode !== 'add'),
            bdoPeopleCollection: new FormControl(inputData.bdoPeopleCollection && !inputData.bdo && this.mode !== 'add'),
            emailOrderReminder: new FormControl(this.mode === 'add' ? true : inputData.emailOrderReminder),
            reinvoice: new FormControl((inputData.reinvoice) || false),
            price: new FormControl(inputData.price ? String(inputData.price).replace('.', ',') : ''),
            reinvoiceNumber: new FormControl((inputData.reinvoiceNumber) || ''),
            invoiceComment: new FormControl((inputData.invoiceComment) || ''),
            referenceNumber: new FormControl(this.mode === 'clone' ? null : inputData.referenceNumber || null),
            orderType: new FormControl(inputData.orderType || OrderTypes.Receiving),
            status: new FormControl(this.mode === 'clone' ? 'new' : inputData.status || 'new'),
            customerCompanyBranchOther: new FormGroup({
                name: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.name) ||
                    null, inputData.customerCompanyBranch && inputData.customerCompanyBranch.name ? Validators.required : null),
                street: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.street) ||
                    null, Validators.required),
                postalCode: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.postalCode) ||
                    null, Validators.required),
                city: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.city) ||
                    null, Validators.required),
                country: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.country) ||
                    'Polska', Validators.required),
                contact: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.contact) ||
                    null),
                email: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.email) ||
                    null),
                phone: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.phone) ||
                    null, Validators.required),
                note: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.note) ||
                    null),
                mainBranch: new FormControl(false)
            })
        });
        if (this.mode === 'view') {
            this.orderReceivingForm.disable();
        }
        if (this.mode === 'view' && !this.canUserEdit) {
            this.orderReceivingForm.disable();
            this.orderReceivingForm.get('suggestedDate').enable();
        }
        if (this.mode === 'add' || this.mode === 'clone' || this.user.userType !== this.userTypes.Logistician) {
            this.orderReceivingForm.get('status').disable();
            this.orderReceivingForm.get('customerCompanyBranch').disable();
        }
        if (this.mode === 'clone' && this.orderReceivingForm.get('bdoFree').value || this.orderReceivingForm.get('bdo').value) {
            this.orderReceivingForm.get('bdo').disable();
        }
        if (this.mode === 'add' || this.mode === 'clone' || this.mode === 'edit') {
            this.orderReceivingForm.get('bdoFree').valueChanges.subscribe(function (bdoFree) {
                var pickedCustomerCompany = _this.orderReceivingForm.get('customerCompany').value;
                var bdoPeopleCollection = _this.orderReceivingForm.get('bdoPeopleCollection').value;
                if (bdoFree) {
                    _this.orderReceivingForm.get('bdoPeopleCollection').setValue(false);
                    _this.orderReceivingForm.get('bdo').clearValidators();
                    _this.orderReceivingForm.get('bdo').setValue('');
                }
                else if (!bdoFree && !bdoPeopleCollection && ((pickedCustomerCompany && pickedCustomerCompany.bdo) || (_this.order && _this.order.bdo))) {
                    _this.orderReceivingForm.get('bdo').setValidators(Validators.required);
                    _this.orderReceivingForm.get('bdo').setValue(pickedCustomerCompany.bdo || _this.order.bdo);
                }
                else if (!bdoFree && !bdoPeopleCollection && (!pickedCustomerCompany || !pickedCustomerCompany.bdo && _this.mode !== 'clone')) {
                    _this.orderReceivingForm.get('bdo').setValidators(Validators.required);
                    _this.orderReceivingForm.get('bdo').enable();
                }
                else {
                    _this.orderReceivingForm.get('bdo').setValue(_this.bdo);
                }
            });
            this.orderReceivingForm.get('bdoPeopleCollection').valueChanges.subscribe(function (bdoPeopleCollection) {
                var bdoFree = _this.orderReceivingForm.get('bdoFree').value;
                if (bdoPeopleCollection) {
                    _this.orderReceivingForm.get('bdoFree').setValue(false);
                    _this.orderReceivingForm.get('bdo').clearValidators();
                    _this.orderReceivingForm.get('bdo').setValue('');
                    _this.orderReceivingForm.get('bdo').disable();
                }
                else if (!bdoPeopleCollection && !bdoFree) {
                    _this.orderReceivingForm.get('bdo').setValidators(Validators.required);
                    _this.orderReceivingForm.get('bdo').enable();
                }
            });
        }
        if (this.mode === 'add' || this.mode === 'clone' || this.mode === 'edit') {
            this.orderReceivingForm.get('customerCompany').valueChanges.subscribe(function (pickedCustomerCompany) {
                var bdoFree = _this.orderReceivingForm.get('bdoFree').value;
                var bdoPeopleCollection = _this.orderReceivingForm.get('bdoPeopleCollection').value;
                if (pickedCustomerCompany && pickedCustomerCompany.bdo && !bdoFree && !bdoPeopleCollection) {
                    _this.orderReceivingForm.get('bdo').setValue(pickedCustomerCompany.bdo);
                    _this.orderReceivingForm.get('bdo').disable();
                }
                else if (pickedCustomerCompany && !pickedCustomerCompany.bdo && !bdoFree && !bdoPeopleCollection) {
                    _this.orderReceivingForm.get('bdo').setValue('');
                    _this.orderReceivingForm.get('bdo').enable();
                }
                else if (pickedCustomerCompany && !pickedCustomerCompany.bdo && (bdoFree || bdoPeopleCollection)) {
                    _this.orderReceivingForm.get('bdo').setValue('');
                    _this.orderReceivingForm.get('bdo').disable();
                }
            });
        }
        if (this.orderReceivingForm.get('reinvoice').value === false) {
            this.orderReceivingForm.get('price').disable();
            this.orderReceivingForm.get('reinvoiceNumber').disable();
        }
    };
    OrderReceivingFormComponent.prototype.checkCustomerCompaniesMissing = function () {
        var chosenCustomerCompany = this.orderReceivingForm.get('customerCompany')
            .value;
        if (chosenCustomerCompany &&
            !this.customerCompanyOptions.find(function (item) { return item.uuid === chosenCustomerCompany.uuid; })) {
            this.customerCompanyOptions.push(chosenCustomerCompany);
        }
    };
    OrderReceivingFormComponent.prototype.createSearchFilters = function () {
        var _this = this;
        this.searchCustomerCompany = new FormControl('');
        this.searchCustomerCompany.valueChanges
            .debounceTime(300)
            .subscribe(function (value) {
            _this.filteredListsService
                .getCompaniesList(__assign({}, _this.filterParams, { search: value }))
                .subscribe(function (response) {
                _this.customerCompanyOptions = response.customerCompanies;
                !value && _this.checkCustomerCompaniesMissing();
            });
        });
    };
    OrderReceivingFormComponent.prototype.reinvoiceChange = function (reinvoiceChecked) {
        if (reinvoiceChecked) {
            this.orderReceivingForm.get('price').enable();
            this.orderReceivingForm.get('reinvoiceNumber').enable();
        }
        else {
            this.orderReceivingForm.get('price').setValue(null);
            this.orderReceivingForm.get('price').disable();
            this.orderReceivingForm.get('reinvoiceNumber').setValue('');
            this.orderReceivingForm.get('reinvoiceNumber').disable();
        }
    };
    OrderReceivingFormComponent.prototype.handleCCBranchChange = function (branchId) {
        var branch = this.customerCompanyBranches && this.customerCompanyBranches.find(function (item) { return item.uuid === branchId; });
        this.orderReceivingForm.get('customerCompanyBranchOther').patchValue({
            name: (branch && branch.name) || null,
            street: (branch && branch.street) || null,
            postalCode: (branch && branch.postalCode) || null,
            city: (branch && branch.city) || null,
            country: (branch && branch.country) || 'Polska',
            contact: (branch && branch.contact) || null,
            email: (branch && branch.email) || null,
            phone: (branch && branch.phone) || null,
            note: (branch && branch.note) || null,
            mainBranch: false
        });
    };
    OrderReceivingFormComponent.prototype.getTooltip = function (branch) {
        return branch.name + "\n    " + branch.street + "\n    " + branch.postalCode + " " + branch.city;
    };
    OrderReceivingFormComponent.prototype.getTooltipCompany = function (company) {
        if (company) {
            return company.name + " \n      " + company.address.street + "\n      " + company.address.postalCode + " " + company.address.city;
        }
    };
    OrderReceivingFormComponent.prototype.clearStartDate = function (e) {
        e.stopPropagation();
        this.orderReceivingForm.get('suggestedDate').reset();
    };
    OrderReceivingFormComponent.prototype.closeDialog = function () {
        var _this = this;
        if (!this.orderReceivingForm.dirty) {
            this.orderEditorDialogRef.close();
            return;
        }
        if (this.isCloseDialogOpen === false) {
            var saveDialogRef = this.matDialog.open(ConfirmModalComponent, {
                disableClose: true,
                autoFocus: false
            });
            saveDialogRef.componentInstance.confirmMessage = this.translateService.instant('SHARED.MESSAGES.CLOSE_DIALOG_WITHOUT_SAVE');
            saveDialogRef.componentInstance.titleMessage = this.translateService.instant('SHARED.MESSAGES.USAVED_DATA');
            saveDialogRef.componentInstance.confirmButton = this.translateService.instant('SHARED.NO');
            saveDialogRef.componentInstance.skipButton = this.translateService.instant('SHARED.YES');
            this.isCloseDialogOpen = true;
            saveDialogRef.afterClosed().subscribe(function (result) {
                _this.isCloseDialogOpen = false;
                if (!result) {
                    _this.orderEditorDialogRef.close();
                }
            });
        }
    };
    return OrderReceivingFormComponent;
}());
export { OrderReceivingFormComponent };
