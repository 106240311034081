var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MatOption, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { FuseTranslationLoaderService } from '../../../../../../@fuse/services/translation-loader.service';
import { OrderExport, OrderSatuses } from '../../../../../shared/models/orders.model';
import { OrdersService } from '../../../../../services/orders.service';
import { locale as polish } from '../../../../../translations/pl';
import { locale as english } from '../../../../../translations/en';
import { ConfirmModalComponent } from '../../../../shared/components/confirm-modal/confirm-modal.component';
import { AuthService } from '../../../../../services/auth.service';
import { RoleTypes, UserTypes } from '../../../../../shared/models/users.model';
import { map, switchMap } from 'rxjs/operators';
import { zip } from 'rxjs';
import * as moment from 'moment';
import { FilteredListsService } from '../../../../../services/filtered-lists.service';
import { OrderTypes } from '../../../../../shared/consts/orders';
import { TaskExportFormComponent } from '../../../../tasks/components/task-forms/task-export-form/task-export-form.component';
import { HistoryDialogComponent } from '../../../../shared/components/history-dialog/history-dialog.component';
import { ItemsOrigins } from '../../../../../shared/models/history.model';
import { ValidateGreaterThanZero } from '../../../../../shared/validators/greaterThanZero.validator';
import { CompanyEditorComponent } from '../../../../companies/components/company-editor/company-editor.component';
import { CompanyBranchesService } from '../../../../../services/company-branches.service';
import { markFormGroupTouched } from "../../../../../shared/utils/markFormGroupAsTouched";
var OrderExportFormComponent = /** @class */ (function () {
    function OrderExportFormComponent(orderEditorDialogRef, data, matDialog, authService, ordersService, filteredListsService, translateService, companyBranchesService, snackBar, formBuilder, _fuseTranslationLoaderService) {
        this.orderEditorDialogRef = orderEditorDialogRef;
        this.data = data;
        this.matDialog = matDialog;
        this.authService = authService;
        this.ordersService = ordersService;
        this.filteredListsService = filteredListsService;
        this.translateService = translateService;
        this.companyBranchesService = companyBranchesService;
        this.snackBar = snackBar;
        this.formBuilder = formBuilder;
        this._fuseTranslationLoaderService = _fuseTranslationLoaderService;
        this.orderStatuses = OrderSatuses;
        this.userTypes = UserTypes;
        this.roleTypes = RoleTypes;
        this.canUserEdit = false;
        this.canCancelOrder = false;
        this.canGenerateTask = false;
        this.canViewTask = false;
        this.fractionOptions = [];
        this.transportOptions = [];
        this.customerCompanyOptions = [];
        this.customerCompanyBranches = [];
        this.isLoading = false;
        this.filterParams = {
            page: 1,
            limit: 20,
            sort: 'name',
            order: 'asc'
        };
        this.filterParamsFullList = {
            page: 1,
            limit: 1000,
            sort: 'name',
            order: 'asc',
            active: 'true'
        };
        this.isCloseDialogOpen = false; // without this, you can open the dialog multiple times
        this.mode = data.mode;
        this.orderUuid = data.orderUuid;
        this.user = this.authService.user.user;
        this._fuseTranslationLoaderService.loadTranslations(polish, english);
    }
    OrderExportFormComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createForm();
        this.createSearchFilters();
        if (this.mode === 'edit' || this.mode === 'view' || this.mode === 'clone') {
            this.ordersService
                .getOrder(this.orderUuid)
                .pipe(map(function (res) {
                _this.order = res;
                _this.canCancelOrder =
                    _this.order &&
                        _this.order.status !== _this.orderStatuses.Cancelled &&
                        (_this.user.roleType === _this.roleTypes.Admin ||
                            _this.user.userType === _this.userTypes.Logistician ||
                            _this.user.uuid === _this.order.createdBy.uuid);
                _this.canUserEdit =
                    _this.order &&
                        !_this.order.taskNumber &&
                        (_this.user.roleType === _this.roleTypes.Admin ||
                            _this.user.userType === _this.userTypes.Logistician ||
                            _this.user.uuid === _this.order.createdBy.uuid);
                _this.canGenerateTask =
                    _this.order &&
                        _this.order.status !== _this.orderStatuses.Cancelled &&
                        !_this.order.taskNumber &&
                        _this.user.userType === _this.userTypes.Logistician;
                _this.canViewTask =
                    _this.order &&
                        _this.order.taskNumber &&
                        _this.user.userType === _this.userTypes.Logistician;
            }), switchMap(function () {
                return zip(_this.filteredListsService.getFractionsList(_this.filterParamsFullList), _this.filteredListsService.getTransportList(_this.filterParamsFullList), _this.filteredListsService.getCompaniesList(_this.filterParams));
            }))
                .subscribe(function (_a) {
                var fractionsRes = _a[0], transportRes = _a[1], companiesRes = _a[2];
                _this.fractionOptions = fractionsRes.fractions;
                _this.transportOptions = transportRes.transports;
                _this.customerCompanyOptions = companiesRes.customerCompanies;
                _this.createForm(_this.order);
                _this.checkCustomerCompaniesMissing();
                _this.checkTransportMissing();
                _this.checkFractionsMissing();
                _this.getCustomerCompanyBranches(_this.order.customerCompany.uuid);
            });
        }
        else {
            this.todayDate = new Date();
            this.filteredListsService
                .getFractionsList(this.filterParamsFullList)
                .subscribe(function (res) { return (_this.fractionOptions = res.fractions); });
            this.filteredListsService
                .getTransportList(this.filterParamsFullList)
                .subscribe(function (res) { return (_this.transportOptions = res.transports); });
            this.filteredListsService
                .getCompaniesList(this.filterParams)
                .subscribe(function (res) { return (_this.customerCompanyOptions = res.customerCompanies); });
        }
    };
    OrderExportFormComponent.prototype.submitOrder = function () {
        var _this = this;
        this.isLoading = true;
        markFormGroupTouched(this.orderExportForm);
        if (this.orderExportForm.invalid) {
            this.isLoading = false;
            return;
        }
        var newOrder = JSON.parse(JSON.stringify(this.orderExportForm.value));
        if (newOrder.suggestedDate) {
            newOrder.suggestedDate = moment(newOrder.suggestedDate).format('YYYY-MM-DD');
        }
        newOrder.fractionsId =
            newOrder.fractions && newOrder.fractions.map(function (fraction) { return fraction.uuid; });
        newOrder.transportId = newOrder.transport ? newOrder.transport.uuid : null;
        newOrder.customerCompanyId = newOrder.customerCompany
            ? newOrder.customerCompany.uuid
            : null;
        var CCBranchId = newOrder.customerCompanyBranch;
        newOrder.customerCompanyBranch = __assign({}, newOrder.customerCompanyBranchOther, { uuid: CCBranchId !== 'other' ? CCBranchId : null });
        newOrder.customerCompanyBranch.email = newOrder.customerCompanyBranch.email || null;
        newOrder.price = Number(String(newOrder.price).replace(',', '.')) || null;
        delete newOrder.customerCompanyBranchOther;
        delete newOrder.fractions;
        delete newOrder.transport;
        delete newOrder.customerCompany;
        if (this.mode === 'add' || this.mode === 'clone') {
            this.ordersService.createOrder(newOrder).subscribe(function () {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CREATE_SUCCESS'), '', { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CREATE_ERROR'), '', { duration: 5000 });
            });
        }
        else if (this.mode === 'view' && !this.canUserEdit) {
            this.ordersService.updateOrderDate(this.orderUuid, newOrder.suggestedDate).subscribe(function () {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.UPDATE_SUCCESS'), '', { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.UPDATE_ERROR'), '', { duration: 5000 });
            });
        }
        else {
            this.ordersService.updateOrder(this.orderUuid, newOrder).subscribe(function () {
                _this.orderEditorDialogRef.close();
                _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                    _this.ordersService.onEditEvent.emit();
                });
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.UPDATE_SUCCESS'), '', { duration: 5000 });
            }, function () {
                _this.isLoading = false;
                _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.UPDATE_ERROR'), '', { duration: 5000 });
            });
        }
    };
    OrderExportFormComponent.prototype.deleteOrder = function () {
        var _this = this;
        var deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false
        });
        deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant('ORDERS.MESSAGES.DELETE_QUESTION');
        deleteDialogRef.componentInstance.titleMessage = this.translateService.instant('ORDERS.MESSAGES.DELETE_ORDER_TITLE');
        deleteDialogRef.componentInstance.confirmButton = this.translateService.instant('ORDERS.DELETE');
        deleteDialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.ordersService.deleteOrder(_this.orderUuid).subscribe(function () {
                    _this.orderEditorDialogRef.close();
                    _this.orderEditorDialogRef.afterClosed().subscribe(function () {
                        _this.ordersService.onEditEvent.emit();
                    });
                    _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.DELETE_SUCCESS'), '', { duration: 5000 });
                }, function () {
                    _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.DELETE_ERROR'), '', { duration: 5000 });
                });
            }
        });
    };
    OrderExportFormComponent.prototype.generateTask = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(TaskExportFormComponent, {
            panelClass: 'task-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add',
                orders: [this.order]
            }
        });
    };
    OrderExportFormComponent.prototype.viewTask = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(TaskExportFormComponent, {
            panelClass: 'task-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'view',
                taskNumber: this.order.taskNumber
            }
        });
    };
    OrderExportFormComponent.prototype.changeOrderStatus = function (status) {
        var _this = this;
        var changeStatusDialogRef = this.matDialog.open(ConfirmModalComponent, {
            disableClose: false,
            autoFocus: false
        });
        if (status === this.orderStatuses.Planned) {
            changeStatusDialogRef.componentInstance.confirmMessage = this.translateService.instant('ORDERS.MESSAGES.PLAN_QUESTION');
            changeStatusDialogRef.componentInstance.titleMessage = this.translateService.instant('ORDERS.MESSAGES.PLAN_ORDER_TITLE');
            changeStatusDialogRef.componentInstance.confirmButton = this.translateService.instant('ORDERS.PLAN_ORDER');
            changeStatusDialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.ordersService
                        .changeOrderStatus(_this.orderUuid, _this.orderStatuses.Planned)
                        .subscribe(function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.PLAN_SUCCESS'), '', { duration: 5000 });
                        _this.order.status = _this.orderStatuses.Planned;
                        _this.ordersService.onEditEvent.emit();
                    }, function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.PLAN_ERROR'), '', { duration: 5000 });
                    });
                }
            });
        }
        else {
            changeStatusDialogRef.componentInstance.confirmMessage = this.translateService.instant('ORDERS.MESSAGES.CANCEL_QUESTION');
            changeStatusDialogRef.componentInstance.titleMessage = this.translateService.instant('ORDERS.MESSAGES.CANCEL_ORDER_TITLE');
            changeStatusDialogRef.componentInstance.confirmButton = this.translateService.instant('ORDERS.CANCEL_ORDER');
            changeStatusDialogRef.afterClosed().subscribe(function (result) {
                if (result) {
                    _this.ordersService
                        .changeOrderStatus(_this.orderUuid, _this.orderStatuses.Cancelled)
                        .subscribe(function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CANCEL_SUCCESS'), '', { duration: 5000 });
                        _this.order.status = _this.orderStatuses.Cancelled;
                        _this.canCancelOrder = false;
                        _this.ordersService.onEditEvent.emit();
                        _this.orderEditorDialogRef.close();
                    }, function () {
                        _this.snackBar.open(_this.translateService.instant('ORDERS.MESSAGES.CANCEL_ERROR'), '', { duration: 5000 });
                    });
                }
            });
        }
    };
    OrderExportFormComponent.prototype.compareObjectsByUuid = function (val1, val2) {
        return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
    };
    OrderExportFormComponent.prototype.getTitle = function () {
        switch (this.mode) {
            case 'add':
            case 'clone':
                return this.translateService.instant('ORDERS.TITLE_ADD');
            case 'edit':
                return this.translateService.instant('ORDERS.TITLE_EDIT');
            case 'view':
                return this.translateService.instant('ORDERS.TITLE_VIEW');
            default:
                return;
        }
    };
    OrderExportFormComponent.prototype.showHistory = function () {
        this.matDialog.open(HistoryDialogComponent, {
            panelClass: 'history-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                item: this.order,
                itemOrigin: ItemsOrigins.Order,
                itemId: this.orderUuid,
                itemNumber: this.order.orderNumber
            }
        });
    };
    OrderExportFormComponent.prototype.enableEditMode = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(OrderExportFormComponent, {
            panelClass: 'order-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'edit',
                orderUuid: this.orderUuid
            }
        });
    };
    OrderExportFormComponent.prototype.enableCloneMode = function () {
        this.orderEditorDialogRef.close();
        this.matDialog.open(OrderExportFormComponent, {
            panelClass: 'order-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'clone',
                orderUuid: this.orderUuid
            }
        });
    };
    OrderExportFormComponent.prototype.onChangeCustomerCompany = function (company) {
        return __awaiter(this, void 0, void 0, function () {
            var ccMainBranch;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(company && company.uuid)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.getCustomerCompanyBranches(company.uuid)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        ccMainBranch = this.customerCompanyBranches[0];
                        this.orderExportForm.get('customerCompanyBranch').enable();
                        this.orderExportForm.patchValue({
                            customerCompany: company,
                            customerCompanyBranch: ccMainBranch ? ccMainBranch.uuid || 'other' : null,
                            customerCompanyBranchOther: {
                                name: (ccMainBranch && ccMainBranch.name) || null,
                                street: (ccMainBranch && ccMainBranch.street) || null,
                                postalCode: (ccMainBranch && ccMainBranch.postalCode) || null,
                                city: (ccMainBranch && ccMainBranch.city) || null,
                                country: (ccMainBranch && ccMainBranch.country) || 'Polska',
                                contact: (ccMainBranch && ccMainBranch.contact) || null,
                                email: (ccMainBranch && ccMainBranch.email) || null,
                                phone: (ccMainBranch && ccMainBranch.phone) || null,
                                note: (ccMainBranch && ccMainBranch.note) || null,
                                mainBranch: false
                            }
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    OrderExportFormComponent.prototype.openEmailClient = function () {
        var orderData = this.orderExportForm.getRawValue();
        if (orderData.customerCompanyBranchOther.email) {
            return "mailto:" + orderData.customerCompanyBranchOther.email;
        }
    };
    OrderExportFormComponent.prototype.canSendEmail = function () {
        return this.mode === 'view' && this.orderExportForm.getRawValue().customerCompanyBranchOther.email;
    };
    OrderExportFormComponent.prototype.fractionSelectOptionChange = function (option) {
        var tmpSelection = this.orderExportForm.get('fractions').value;
        if (!tmpSelection.find(function (i) { return i.uuid === option.value.uuid; })) {
            this.orderExportForm.get('fractions').setValue(tmpSelection.concat([option.value]));
        }
        else {
            this.orderExportForm.get('fractions').setValue(tmpSelection.filter(function (i) { return i.uuid !== option.value.uuid; }));
        }
    };
    OrderExportFormComponent.prototype.getCustomerCompanyBranches = function (customerCompanyId) {
        return __awaiter(this, void 0, void 0, function () {
            var res, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.companyBranchesService
                                .getCompanyBranchesList({ limit: 500, page: 0 }, customerCompanyId).toPromise()];
                    case 1:
                        res = _a.sent();
                        this.customerCompanyBranches = res.branches;
                        return [2 /*return*/, true];
                    case 2:
                        e_1 = _a.sent();
                        return [2 /*return*/, false];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    OrderExportFormComponent.prototype.addCustomerCompany = function () {
        var _this = this;
        var editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
            panelClass: 'company-editor-dialog',
            autoFocus: false,
            disableClose: true,
            data: {
                mode: 'add'
            }
        });
        editorDialogRef.componentInstance.onAddCompany.subscribe(function (res) {
            _this.customerCompanyOptions.push(res);
            _this.onChangeCustomerCompany(res);
            _this.companySelect.close();
        });
    };
    OrderExportFormComponent.prototype.createForm = function (inputData) {
        if (inputData === void 0) { inputData = new OrderExport(); }
        this.orderExportForm = this.formBuilder.group({
            fractions: new FormControl(inputData.fractions || null, Validators.required),
            value: new FormControl(inputData.value || null, [
                Validators.required,
                Validators.pattern('[\\d]+[.]{0,1}[\\d]{0,3}'),
                ValidateGreaterThanZero
            ]),
            unit: new FormControl(inputData.unit || 'kg', Validators.required),
            customerCompany: new FormControl(inputData.customerCompany || null),
            customerCompanyBranch: new FormControl(inputData.customerCompanyBranch
                ? inputData.customerCompanyBranch.uuid
                    ? inputData.customerCompanyBranch.uuid
                    : 'other'
                : null, Validators.required),
            transport: new FormControl(inputData.transport || null, Validators.required),
            isReady: new FormControl(inputData.isReady || false, Validators.required),
            reinvoice: new FormControl((inputData.reinvoice) || false),
            price: new FormControl(inputData.price ? String(inputData.price).replace('.', ',') : ''),
            reinvoiceNumber: new FormControl((inputData.reinvoiceNumber) || ''),
            invoiceComment: new FormControl((inputData.invoiceComment) || ''),
            suggestedDate: new FormControl(this.mode === 'clone' ? null : inputData.suggestedDate || null),
            tags: new FormControl(inputData.tags || []),
            comment: new FormControl(this.mode === 'clone' ? null : inputData.comment || null),
            referenceNumber: new FormControl(this.mode === 'clone' ? null : inputData.referenceNumber || null),
            companyBranchId: new FormControl(inputData.companyBranch
                ? inputData.companyBranch.uuid
                : this.user.companyBranchId, Validators.required),
            orderType: new FormControl(inputData.orderType || OrderTypes.Export),
            status: new FormControl(this.mode === 'clone' ? 'new' : inputData.status || 'new'),
            customerCompanyBranchOther: new FormGroup({
                name: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.name) ||
                    null, inputData.customerCompanyBranch && inputData.customerCompanyBranch.name ? Validators.required : null),
                street: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.street) ||
                    null, Validators.required),
                postalCode: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.postalCode) ||
                    null, Validators.required),
                city: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.city) ||
                    null, Validators.required),
                country: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.country) ||
                    'Polska', Validators.required),
                contact: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.contact) ||
                    null),
                email: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.email) ||
                    null),
                phone: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.phone) ||
                    null, Validators.required),
                note: new FormControl((inputData.customerCompanyBranch &&
                    inputData.customerCompanyBranch.note) ||
                    null),
                mainBranch: new FormControl(false)
            })
        });
        if (this.mode === 'view') {
            this.orderExportForm.disable();
        }
        if (this.mode === 'view' && !this.canUserEdit) {
            this.orderExportForm.disable();
            this.orderExportForm.get('suggestedDate').enable();
        }
        if (this.mode === 'add' ||
            this.mode === 'clone' ||
            this.user.userType !== this.userTypes.Logistician) {
            this.orderExportForm.get('status').disable();
            this.orderExportForm.get('customerCompanyBranch').disable();
        }
        if (this.orderExportForm.get('reinvoice').value === false) {
            this.orderExportForm.get('price').disable();
            this.orderExportForm.get('reinvoiceNumber').disable();
        }
    };
    OrderExportFormComponent.prototype.checkCustomerCompaniesMissing = function () {
        var chosenCustomerCompany = this.orderExportForm.get('customerCompany')
            .value;
        if (chosenCustomerCompany &&
            !this.customerCompanyOptions.find(function (item) { return item.uuid === chosenCustomerCompany.uuid; })) {
            this.customerCompanyOptions.push(chosenCustomerCompany);
        }
    };
    OrderExportFormComponent.prototype.checkTransportMissing = function () {
        var chosenTransport = this.orderExportForm.get('transport').value;
        if (chosenTransport &&
            !this.transportOptions.find(function (item) { return item.uuid === chosenTransport.uuid; })) {
            this.transportOptions.push(chosenTransport);
        }
    };
    OrderExportFormComponent.prototype.checkFractionsMissing = function () {
        var _this = this;
        var chosenFractions = this.orderExportForm.get('fractions').value;
        chosenFractions &&
            chosenFractions.forEach(function (fraction) {
                if (fraction &&
                    !_this.fractionOptions.find(function (item) { return item.uuid === fraction.uuid; })) {
                    _this.fractionOptions.push(fraction);
                }
            });
    };
    OrderExportFormComponent.prototype.createSearchFilters = function () {
        var _this = this;
        this.searchCustomerCompany = new FormControl('');
        this.searchTransport = new FormControl('');
        this.searchFractions = new FormControl('');
        this.searchCustomerCompany.valueChanges
            .debounceTime(300)
            .subscribe(function (value) {
            _this.filteredListsService
                .getCompaniesList(__assign({}, _this.filterParams, { search: value }))
                .subscribe(function (response) {
                _this.customerCompanyOptions = response.customerCompanies;
                !value && _this.checkCustomerCompaniesMissing();
            });
        });
        this.searchTransport.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService
                .getTransportList(__assign({}, _this.filterParamsFullList, { name: value }))
                .subscribe(function (response) {
                _this.transportOptions = response.transports;
                !value && _this.checkTransportMissing();
            });
        });
        this.searchFractions.valueChanges.debounceTime(300).subscribe(function (value) {
            _this.filteredListsService
                .getFractionsList(__assign({}, _this.filterParamsFullList, { search: value }))
                .subscribe(function (response) {
                _this.fractionOptions = response.fractions;
                !value && _this.checkFractionsMissing();
            });
        });
    };
    OrderExportFormComponent.prototype.reinvoiceChange = function (reinvoiceChecked) {
        if (reinvoiceChecked) {
            this.orderExportForm.get('price').enable();
            this.orderExportForm.get('reinvoiceNumber').enable();
        }
        else {
            this.orderExportForm.get('price').setValue(null);
            this.orderExportForm.get('price').disable();
            this.orderExportForm.get('reinvoiceNumber').setValue('');
            this.orderExportForm.get('reinvoiceNumber').disable();
        }
    };
    OrderExportFormComponent.prototype.handleCCBranchChange = function (branchId) {
        var branch = this.customerCompanyBranches && this.customerCompanyBranches.find(function (item) { return item.uuid === branchId; });
        this.orderExportForm.get('customerCompanyBranchOther').patchValue({
            name: (branch && branch.name) || null,
            street: (branch && branch.street) || null,
            postalCode: (branch && branch.postalCode) || null,
            city: (branch && branch.city) || null,
            country: (branch && branch.country) || 'Polska',
            contact: (branch && branch.contact) || null,
            email: (branch && branch.email) || null,
            phone: (branch && branch.phone) || null,
            note: (branch && branch.note) || null,
            mainBranch: false
        });
    };
    OrderExportFormComponent.prototype.getTooltip = function (branch) {
        return branch.name + "\n    " + branch.street + "\n    " + branch.postalCode + " " + branch.city;
    };
    OrderExportFormComponent.prototype.getTooltipCompany = function (company) {
        if (company) {
            return company.name + " \n      " + company.address.street + "\n      " + company.address.postalCode + " " + company.address.city;
        }
    };
    OrderExportFormComponent.prototype.clearStartDate = function (e) {
        e.stopPropagation();
        this.orderExportForm.get('suggestedDate').reset();
    };
    OrderExportFormComponent.prototype.closeDialog = function () {
        var _this = this;
        if (!this.orderExportForm.dirty) {
            this.orderEditorDialogRef.close();
            return;
        }
        if (this.isCloseDialogOpen === false) {
            var saveDialogRef = this.matDialog.open(ConfirmModalComponent, {
                disableClose: true,
                autoFocus: false
            });
            saveDialogRef.componentInstance.confirmMessage = this.translateService.instant('SHARED.MESSAGES.CLOSE_DIALOG_WITHOUT_SAVE');
            saveDialogRef.componentInstance.titleMessage = this.translateService.instant('SHARED.MESSAGES.USAVED_DATA');
            saveDialogRef.componentInstance.confirmButton = this.translateService.instant('SHARED.NO');
            saveDialogRef.componentInstance.skipButton = this.translateService.instant('SHARED.YES');
            this.isCloseDialogOpen = true;
            saveDialogRef.afterClosed().subscribe(function (result) {
                _this.isCloseDialogOpen = false;
                if (!result) {
                    _this.orderEditorDialogRef.close();
                }
            });
        }
    };
    return OrderExportFormComponent;
}());
export { OrderExportFormComponent };
