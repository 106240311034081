import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { RequestParams } from '../shared/models/list.model';
import { User, UserAclGroups, UsersListResponse } from '../shared/models/users.model';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { CompanyBranchListResponse } from '../shared/models/company.model';
import { AuthService } from './auth.service';
import { removeAccents } from '../shared/utils/remove-accents';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  onEditEvent: EventEmitter<any> = new EventEmitter();

  constructor(private http: HttpClient, private authService: AuthService) {
  }

  public getUsersList(params: RequestParams): Observable<UsersListResponse> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/users`, {params: params})
      .pipe(map((res: UsersListResponse) => {
        res.users.forEach(user => {
          return user.avatarName = removeAccents(`${user.name} ${user.surname}`);
        });

        return res;
      }));
  }

  getUser(userUuid: User['uuid']): Observable<User> {
    return this.http.get(`${environment.apiUrl}/${this.authService.getUserType()}/users/${userUuid}`).pipe(
      map((res: any) => {
        res.user.avatarName = removeAccents(`${res.user.name} ${res.user.surname}`);
        return res.user;
      })
    );
  }

  createUser(user: User): Observable<User> {
    return this.http.post<any>(`${environment.apiUrl}/${this.authService.getUserType()}/users`, user).pipe(
      map((res: any) => res.user)
    );
  }

  updateUser(userUuid: User['uuid'], user: User): Observable<User> {
    return this.http.put<any>(`${environment.apiUrl}/${this.authService.getUserType()}/users/${userUuid}`, user);
  }

  deleteUser(userUuid: User['uuid']) {
    return this.http.delete<any>(`${environment.apiUrl}/${this.authService.getUserType()}/users/${userUuid}`);
  }

  getCompanyBranchList() {
    return this.http.get<CompanyBranchListResponse>(`${environment.apiUrl}/${this.authService.getUserType()}/companyBranches`);
  }

  public getAclGroupsList() {
    return this.http.get<UserAclGroups>(`${environment.apiUrl}/${this.authService.getUserType()}/users/groups`);
  }
}