<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-wrapper">
        <span class="title dialog-title mr-8">{{getTitle()}} - {{'ORDERS.TYPES.EXPORT' | translate}}</span>
        <span class="title dialog-title" *ngIf="mode !== 'add' && mode !== 'clone'">
          [{{'ORDERS.STATUSES.' + order?.status.toUpperCase() | translate}}]
        </span>
      </div>
      <button mat-icon-button (click)="closeDialog()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <div *ngIf="mode !== 'add' &&  mode !== 'clone' && order" class="full-width-wrapper-vertical mb-24">
      <h3 class="mt-0"> {{'ORDERS.ORDER_NUMBER' | translate}} </h3>
      <div>{{order.orderNumber || ('ORDERS.NO_NUMBER' | translate)}}</div>
      <hr>
    </div>
    <form class="form" [formGroup]="orderExportForm">
      <div class="input-wrapper-order-export">
        <mat-form-field class="multiple-select" appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.FRACTION' | translate}}</mat-label>
          <mat-select formControlName="fractions" multiple required [compareWith]="compareObjectsByUuid"
            [attr.data-cy]="'fraction'">
            <mat-option [attr.data-cy]="'searchFraction'">
              <ngx-mat-select-search [formControl]="searchFractions"
                [placeholderLabel]="'FRACTIONS.SEARCH_PLACEHOLDER' | translate"
                [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option class="full-name-option" *ngFor="let fraction of fractionOptions" [value]="fraction"
              [attr.data-cy]="fraction.name"
              (onSelectionChange)="$event.isUserInput && fractionSelectOptionChange($event.source)">
              {{ fraction.name }}
              <ng-container *ngIf="fraction.code">[<small>{{ fraction.code }}</small>]</ng-container>
              <div *ngIf="fraction.description"><small>{{ fraction.description }}</small></div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-export">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.QUANTITY_KG' | translate}}</mat-label>
          <input formControlName="value" matInput required [attr.data-cy]="'value'">
          <mat-error *ngIf="orderExportForm.get('value').touched && orderExportForm.controls['value'].errors?.pattern">
            {{'SHARED.MESSAGES.DECIMAL_ERROR_THREE' | translate}}
          </mat-error>
          <mat-error *ngIf="orderExportForm.get('value').touched && orderExportForm.controls['value'].errors?.smaller">
            {{'SHARED.MESSAGES.SMALLER_EQUAL_ZERO' | translate}}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-export">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.IS_READY' | translate}}</mat-label>
          <mat-select formControlName="isReady" required [attr.data-cy]="'isReady'">
            <mat-option [value]="true" [attr.data-cy]="'YES'">{{'SHARED.YES' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'NO'">{{'SHARED.NO' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-export">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.TRANSPORT' | translate}}</mat-label>
          <mat-select formControlName="transport" [compareWith]="compareObjectsByUuid" required
            [attr.data-cy]="'chooseTransport'">
            <mat-option [attr.data-cy]="'searchTransport'">
              <ngx-mat-select-search [formControl]="searchTransport"
                [placeholderLabel]="'TRANSPORT.SEARCH_PLACEHOLDER' | translate"
                [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let transport of transportOptions" [value]="transport" [attr.data-cy]="transport.name">
              {{ transport.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="full-width-wrapper-vertical full-name-select">
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{'ORDERS.CUSTOMER_COMPANY' | translate}}</mat-label>
          <mat-select formControlName="customerCompany" [compareWith]="compareObjectsByUuid"
            (valueChange)="onChangeCustomerCompany($event)" #companySelect required [attr.data-cy]="'customerCompany'">
            <mat-option id="searchCompany">
              <ngx-mat-select-search [formControl]="searchCustomerCompany"
                [placeholderLabel]="'CUSTOMER_COMPANIES.SEARCH_PLACEHOLDER' | translate"
                [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate" [attr.data-cy]="'searchCompany'">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option (click)="addCustomerCompany()" [attr.data-cy]="'companyAdd'">
              <span class="add-item">
                <mat-icon class="add-item-icon" matSuffix (click)="addCustomerCompany()"
                  [matTooltip]="'CUSTOMER_COMPANIES.TITLE_ADD' | translate" matTooltipPosition="above">
                  add
                </mat-icon>
                {{ 'CUSTOMER_COMPANIES.TITLE_ADD' | translate }}</span>
            </mat-option>
            <mat-option class="full-name-option" *ngFor="let customerCompany of customerCompanyOptions"
              [value]="customerCompany" [attr.data-cy]="customerCompany.name.split(' ').join('-')">
              <span matTooltipPosition="above" matTooltipClass="multiline-tooltip"
                [matTooltip]="getTooltipCompany(customerCompany)">
                {{ customerCompany.name }}
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{'ORDERS.DESTINATION' | translate}}</mat-label>
          <mat-select formControlName="customerCompanyBranch" required (ngModelChange)="handleCCBranchChange($event)"
            [attr.data-cy]="'customerCompanyBranch'">
            <mat-option class="full-name-option" *ngFor="let branch of customerCompanyBranches; let i=index"
              [value]="branch.uuid" [attr.data-cy]="branch.name">
              <span matTooltipPosition="above" matTooltipClass="multiline-tooltip" [matTooltip]="getTooltip(branch)">
                {{ branch.name }}
              </span>
            </mat-option>
            <mat-option value="other" [attr.data-cy]="'otherBranch'">
              {{ "ORDERS.CUSTOMER_COMPANY_BRANCH.OTHER" | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-export-reverse">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.SUGGESTED_DATE_EXPORT' | translate}}</mat-label>
          <input matInput [matDatepicker]="suggestedDate" formControlName="suggestedDate" [min]="todayDate" readonly>
          <mat-datepicker-toggle *ngIf="mode !== 'view'" matSuffix [for]="suggestedDate"
            [attr.data-cy]="'suggestedDate'"></mat-datepicker-toggle>
          <mat-datepicker #suggestedDate></mat-datepicker>
        </mat-form-field>
        <button mat-icon-button class="clear-date" *ngIf="mode !== 'view'" (click)="clearStartDate($event)">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div class="input-wrapper-order-export-reverse">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.REFERENCE_NUMBER' | translate}}</mat-label>
          <input formControlName="referenceNumber" matInput [attr.data-cy]="'referenceNumber'">
        </mat-form-field>
      </div>

      <div *ngIf="orderExportForm.get('customerCompanyBranch').value" formGroupName="customerCompanyBranchOther"
        class="full-width-wrapper-horizontal">
        <div class="full-width-wrapper-horizontal">
          <h3> {{'ORDERS.DESTINATION' | translate}}</h3>
        </div>
        <div></div>
        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.NAME' | translate}}</mat-label>
            <input formControlName="name" matInput required [attr.data-cy]="'otherBranchName'">
          </mat-form-field>
        </div>
        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.STREET' | translate}}</mat-label>
            <input formControlName="street" matInput required [attr.data-cy]="'otherBranchStreet'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.POSTAL_CODE' | translate}}</mat-label>
            <input formControlName="postalCode" matInput required [attr.data-cy]="'otherBranchPostalCode'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CITY' | translate}}</mat-label>
            <input formControlName="city" matInput required [attr.data-cy]="'otherBranchCity'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.COUNTRY' | translate}}</mat-label>
            <input formControlName="country" matInput required [attr.data-cy]="'otherBranchCountry'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CONTACT' | translate}}</mat-label>
            <input formControlName="contact" matInput [attr.data-cy]="'otherBranchContact'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.PHONE' | translate}}</mat-label>
            <input formControlName="phone" matInput required [attr.data-cy]="'otherBranchPhone'">
          </mat-form-field>
        </div>

        <!-- <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.EMAIL' | translate}}</mat-label>
            <a *ngIf="canSendEmail()" [href]="openEmailClient()" class="email-redirect">
              <input formControlName="email" matInput class="email-redirect" [attr.data-cy]="'otherBranchEmail'">
            </a>
            <input *ngIf="!canSendEmail()" formControlName="email" matInput [attr.data-cy]="'otherBranchEmail'">
            <mat-error *ngIf="orderExportForm.get('customerCompanyBranchOther.email').invalid">{{
              'SHARED.VALIDATORS.INCORRECT_EMAIL' | translate }}</mat-error>
          </mat-form-field>
        </div> -->

        <div  class="full-width-wrapper-vertical">
          <app-email-chips-input [mode]="mode" [emailControl]="orderExportForm.get('customerCompanyBranchOther').controls['email']">
          </app-email-chips-input>
        </div>

        <div class="full-width-wrapper-vertical">
          <h3> {{'ORDERS.CUSTOMER_COMPANY_BRANCH.CLIENT_COMMENT' | translate}}</h3>
          <mat-form-field appearance="outline" fxFlex="auto">
            <textarea rows="4" formControlName="note" matInput [attr.data-cy]="'otherCompanyBranchNote'"></textarea>
          </mat-form-field>
        </div>
      </div>


      <div *ngIf="user.userType === userTypes.Logistician" class="full-width-wrapper-vertical">
        <app-tags-autocomplete [mode]="mode" [tagsControl]="orderExportForm.controls['tags']">
        </app-tags-autocomplete>
      </div> 

      <div class="full-width-wrapper-vertical">
        <h3> {{'ORDERS.INTERNAL_COMMENT' | translate}} </h3>
        <mat-form-field appearance="outline" fxFlex="auto">
          <textarea rows="4" formControlName="comment" matInput [attr.data-cy]="'comment'"></textarea>
        </mat-form-field>
      </div>


      <div class="full-width-wrapper-horizontal reinvoice">
        <div class="input-wrapper-order-export">
          <mat-checkbox formControlName="reinvoice" (change)="reinvoiceChange($event.checked)"
            [attr.data-cy]="'reinvoice'">
            {{'ORDERS.REINVOICE_CHECKBOX' | translate}}
          </mat-checkbox>
        </div>

        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.REINVOICE_VALUE' | translate}}</mat-label>
            <input formControlName="price" matInput [attr.data-cy]="'price'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-export">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.REINVOICE_NUMBER' | translate}}</mat-label>
            <input formControlName="reinvoiceNumber" matInput [attr.data-cy]="'reinvoiceNumber'">
          </mat-form-field>
        </div>
      </div>
      <div class="full-width-wrapper-vertical">
        <h3 class="m-0"> {{'ORDERS.INTERNAL_COMMENT_FV_ISSUER' | translate}}</h3>
        <mat-form-field appearance="outline" fxFlex="auto">
          <textarea formControlName="invoiceComment" matInput [attr.data-cy]="'invoiceComment'"></textarea>
        </mat-form-field>
      </div>

      <div class="full-width-wrapper-vertical">
        <h3> {{'ORDERS.STATUS' | translate}} </h3>
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{'ORDERS.STATUS' | translate}}</mat-label>
          <mat-select formControlName="status">
            <mat-option value="new">{{'ORDERS.STATUSES.NEW' | translate}}</mat-option>
            <mat-option value="read">{{'ORDERS.STATUSES.READ' | translate}}</mat-option>
            <mat-option value="planned">{{'ORDERS.STATUSES.PLANNED' | translate}}</mat-option>
            <mat-option value="cancelled">{{'ORDERS.STATUSES.CANCELLED' | translate}}</mat-option>
            <mat-option value="completed">{{'ORDERS.STATUSES.COMPLETED' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <div *ngIf="mode === 'view' && order">
      <app-order-details-form [orderData]="order"></app-order-details-form>
    </div>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
    <button *ngIf="mode !== 'add' && mode !== 'clone' && mode !== 'edit' && canGenerateTask" mat-button class="mr-8"
      [attr.data-cy]="'generateTask'" (click)="generateTask()">
      {{'ORDERS.GENERATE_TASK' | translate}}
    </button>

    <button *ngIf="mode !== 'add' && mode !== 'clone' && canViewTask" mat-button class="mr-8"
      [attr.data-cy]="'viewTask'" (click)="viewTask()">
      {{'ORDERS.VIEW_TASK' | translate}}
    </button>

    <button *ngIf="mode === 'edit' && canCancelOrder" mat-button class="mr-8" [attr.data-cy]="'cancelOrder'"
      (click)="changeOrderStatus(orderStatuses.Cancelled)">
      {{'ORDERS.CANCEL_ORDER' | translate}}
    </button>

    <button *ngIf="mode === 'edit'" mat-button class="mr-8" [attr.data-cy]="'deleteOrder'" (click)="deleteOrder()">
      {{'ORDERS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view' && user.userType === userTypes.Logistician" mat-button class="mr-8"
      [attr.data-cy]="'historyOrder'" (click)="showHistory()">
      {{'ORDERS.SHOW_HISTORY' | translate}}
    </button>
    <button *ngIf="mode === 'view'" mat-button class="mr-8" [attr.data-cy]="'cloneOrder'" (click)="enableCloneMode()">
      {{'ORDERS.CLONE' | translate}}
    </button>
    <button *ngIf="mode === 'view' && canUserEdit" mat-button class="mr-8" [attr.data-cy]="'editOrder'"
      (click)="enableEditMode()">
      {{'ORDERS.EDIT' | translate}}
    </button>


    <button *ngIf="mode === 'edit' || mode === 'add' || mode ==='clone' || !canUserEdit" mat-button class="save-button"
      (click)="submitOrder()" [attr.data-cy]="'saveOrder'" [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'ORDERS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate" [strokeWidth]="2" [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>
  </div>
</div>