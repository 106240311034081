import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { User } from '../shared/models/users.model';
import { NavigationService } from '../navigation/navigation.service';
import { removeAccents } from '../shared/utils/remove-accents';
import { MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import {MatDialog} from '@angular/material';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _user: { user: User, xsrfToken: string };
  private authCookieName = 'REMONDIS-auth';
  private intervalHandler: any;

  constructor(private cookieService: CookieService,
              private dialogRef: MatDialog,
              private navigationService: NavigationService,
              private router: Router,
              private http: HttpClient,
              private snackBar: MatSnackBar,
              private translateService: TranslateService) {
    if (this.cookieService.getObject(this.authCookieName)) {
      this._user = this.cookieService.getObject(this.authCookieName) as { user: User, xsrfToken: string };
    }
  }

  public login(loginData: { email: string, password: string }): Observable<any> {
    return this.http.post<{ xsrfToken: string, user: User }>(`${environment.apiUrl}/user/session`, {
      email: loginData.email,
      password: loginData.password
    }).pipe(
      tap(response => {
        response.user.avatarName = removeAccents(`${response.user.name} ${response.user.surname}`);
        this.updateSession(response);
        this.navigationService.navigation(response.user.userType, true);
        this.router.navigate([ '/' ]);

        this.intervalHandler = setInterval(() => {
          if (!this.cookieService.getObject(this.authCookieName)) {
            this._user = null;
            this.router.navigate([ '/login' ]);
            this.snackBar.open(this.translateService.instant('SHARED.MESSAGES.SESSION_EXPIRED'), '', {duration: 10000});
            clearInterval(this.intervalHandler);
            this.dialogRef.closeAll();
          }
        }, 60000);
      })
    );
  }

  public logout() {
    if (this._user) {
      return this.http.delete(`${environment.apiUrl}/user/session`).subscribe(() => {
          this.cookieService.remove(this.authCookieName, {path: '/' });
          this._user = null;
          this.router.navigate([ '/login' ]);
          clearInterval(this.intervalHandler);
        },
        () => {
          this.cookieService.remove(this.authCookieName, { path: '/' });
          this._user = null;
          this.router.navigate([ '/login' ]);
          clearInterval(this.intervalHandler);
        }
      );
    }
  }

  public forgotPassword(data: { email: string }) {
    return this.http.post(`${environment.apiUrl}/user/forgot-password`, data);
  }

  public setPassword(data: { password: string, token: string }) {
    return this.http.post(`${environment.apiUrl}/user/set-password`, data);
  }

  public getUser() {
    if (this._user && this._user.user) {
      return this._user.user;
    }
  }

  public getUserType() {
    if (this._user && this._user.user) {
      return this._user.user.userType;
    }
  }

  public updateSession(user) {
    this.setCookie(this.authCookieName, user);
    this._user = user;
  }

  get user() {
    return {...this._user};
  }

  set user(x) {
    throw new Error('Cannot change user');
  }

  public setCookie(cookieName, data) {
    const expirationDate = moment().add(29, 'minutes').toDate();
    this.cookieService.putObject(cookieName, data, { path: '/', expires: expirationDate });
  }

  public removeAuthData() {
    this.cookieService.remove(this.authCookieName, { path: '/' });
    this._user = null;
  }
}
