<div class="full-width-wrapper-vertical">
    <mat-form-field appearance="outline" fxFlex="auto">
      <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.EMAIL' | translate}}</mat-label>
        <mat-chip-list #emailChipList [disabled]="mode==='view'">
            <mat-chip
                    *ngFor="let email of selectedEmails"
                    [selectable]="selectable"
                    [removable]="removable"
                    (removed)="remove(email)">
                {{email}}
                <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
            </mat-chip>
            <input
                    placeholder="{{'ORDERS.CUSTOMER_COMPANY_BRANCH.EMAIL' | translate}}"
                    #emailInput
                    name="email"
                    [disabled]="mode==='view'"
                    [matChipInputFor]="emailChipList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
        <button mat-icon-button (click)="copy()" matSuffix>
          <mat-icon>file_copy</mat-icon>
      </button>
    </mat-form-field>
    <mat-error *ngIf="error">
      {{'SHARED.VALIDATORS.INCORRECT_EMAIL' | translate }}</mat-error>
</div>