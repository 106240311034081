import { NgModule } from "@angular/core";
import { NoAccessComponent } from "./components/no-access/no-access.component";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { FuseSharedModule } from "../../../@fuse/shared.module";
import {
  MatButtonModule,
  MatCheckboxModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatPaginatorModule,
  MatProgressSpinnerModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatSelectModule,
  MatCardModule,
  MatDividerModule,
  MatToolbarModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatSlideToggleModule,
  MatButtonToggleModule,
  MatListModule,
  MAT_DATE_LOCALE,
  MatRadioModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatTabsModule,
  MatChipsModule,
} from "@angular/material";
import { ClipboardModule } from "ngx-clipboard";
import { AuthGuard } from "../../services/guards/auth.guard";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LoginComponent } from "./components/login/login.component";
import { ForgotPasswordComponent } from "./components/forgot-password/forgot-password.component";
import { ChangePasswordComponent } from "./components/change-password/change-password.component";
import { ListFiltersComponent } from "./components/list-filters/list-filters.component";
import { ConfirmModalComponent } from "./components/confirm-modal/confirm-modal.component";
import { AvatarModule } from "ng2-avatar";
import { CommonModule } from "@angular/common";
import { FileDropModule } from "ngx-file-drop";
import { ItemsListPickerFormComponent } from "./components/items-list-picker-form/items-list-picker-form.component";
import { HistoryDialogComponent } from "./components/history-dialog/history-dialog.component";
import { PdfDownloaderComponent } from "./components/pdf-downloader/pdf-downloader.component";
import { OrdersDisplayAarayComponent } from "./components/history-dialog/history-components/task-orders-display-array/task-orders-display-array.component";
import { TaskOrdersChangesArrayComponent } from "./components/history-dialog/history-components/task-orders-changes-array/task-orders-changes-array.component";
import { RecyclingItemsDisplayComponent } from "./components/history-dialog/history-components/recycling-items-display/recycling-items-display.component";
import { RecyclingItemsChangesDisplayComponent } from "./components/history-dialog/history-components/recycling-items-changes-display/recycling-items-changes-display.component";
import { PackageTypesDisplayComponent } from "./components/history-dialog/history-components/package-types-display/package-types-display.component";
import { PackageTypesChangesDisplayComponent } from "./components/history-dialog/history-components/package-types-changes-display/package-types-changes-display.component";
import { TaskOrderChangesComponent } from "./components/history-dialog/history-components/task-order-changes/task-order-changes.component";
import { OrderChangesComponent } from "./components/history-dialog/history-components/order-changes/order-changes.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { TagsAutocompleteComponent } from "./tags-autocomplete/tags-autocomplete.component";
import { TaskPdfDownloaderComponent } from "./components/task-pdf-downloader/task-pdf-downloader.component";
import { EmailChipsInputComponent } from "./components/email-chips-input/email-chips-input.component";

const routes = [
  {
    path: "no-access",
    component: NoAccessComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FuseSharedModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatToolbarModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatAutocompleteModule,
    MatListModule,
    MatExpansionModule,
    MatTabsModule,
    AvatarModule,
    CommonModule,
    FileDropModule,
    NgxMatSelectSearchModule,
    MatChipsModule,
    ClipboardModule,
  ],
  declarations: [
    NoAccessComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ListFiltersComponent,
    ConfirmModalComponent,
    ItemsListPickerFormComponent,
    HistoryDialogComponent,
    PdfDownloaderComponent,
    TaskPdfDownloaderComponent,
    OrdersDisplayAarayComponent,
    TaskOrdersChangesArrayComponent,
    RecyclingItemsDisplayComponent,
    RecyclingItemsChangesDisplayComponent,
    PackageTypesDisplayComponent,
    PackageTypesChangesDisplayComponent,
    TaskOrderChangesComponent,
    OrderChangesComponent,
    TagsAutocompleteComponent,
    EmailChipsInputComponent,
  ],
  exports: [
    FuseSharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AvatarModule,
    CommonModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatRadioModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatButtonToggleModule,
    MatListModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatTabsModule,
    NoAccessComponent,
    LoginComponent,
    NoAccessComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ConfirmModalComponent,
    ListFiltersComponent,
    FileDropModule,
    ItemsListPickerFormComponent,
    HistoryDialogComponent,
    PdfDownloaderComponent,
    TaskPdfDownloaderComponent,
    NgxMatSelectSearchModule,
    TagsAutocompleteComponent,
    EmailChipsInputComponent,
  ],
  entryComponents: [
    ConfirmModalComponent,
    HistoryDialogComponent,
    OrdersDisplayAarayComponent,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: "pl-PL" }],
})
export class SharedModule {}
