<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-accent m-0">
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="space-between center">
      <div class="title-wrapper">
        <span class="title dialog-title">{{getTitle()}} - {{'ORDERS.TYPES.RECEIVING' | translate}}</span>
        <span class="title dialog-title mr-8" *ngIf="mode !== 'add' && mode !== 'clone'">
          [{{'ORDERS.STATUSES.' + order?.status.toUpperCase() | translate}}]
        </span>
      </div>
      <button mat-icon-button (click)="closeDialog()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 pb-0 m-0" fusePerfectScrollbar>
    <div *ngIf="mode !== 'add' && mode !== 'clone' &&  order" class="full-width-wrapper-vertical mb-24">
      <h3 class="mt-0"> {{'ORDERS.ORDER_NUMBER' | translate}} </h3>
      <div>{{order.orderNumber || ('ORDERS.NO_NUMBER' | translate)}}</div>
      <hr>
    </div>
    <form class="form" [formGroup]="orderReceivingForm">
      <div class="full-width-wrapper-vertical full-name-select">
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{'ORDERS.CUSTOMER_COMPANY' | translate}}</mat-label>
          <mat-select formControlName="customerCompany" [compareWith]="compareObjectsByUuid"
            (valueChange)="setCustomerCompany($event)" #companySelect required [attr.data-cy]="'customerCompany'">
            <mat-option id="searchCompany">
              <ngx-mat-select-search [formControl]="searchCustomerCompany"
                [placeholderLabel]="'CUSTOMER_COMPANIES.SEARCH_PLACEHOLDER' | translate"
                [noEntriesFoundLabel]="'SHARED.NO_RESULTS' | translate" [attr.data-cy]="'searchCompany'">
              </ngx-mat-select-search>
            </mat-option>
            <mat-option (click)="addCustomerCompany()" [attr.data-cy]="'addCompany'">
              <span class="add-item">
                <mat-icon class="add-item-icon" matSuffix (click)="addCustomerCompany()"
                  [matTooltip]="'CUSTOMER_COMPANIES.TITLE_ADD' | translate" matTooltipPosition="above">
                  add
                </mat-icon>
                {{ 'CUSTOMER_COMPANIES.TITLE_ADD' | translate }}</span>
            </mat-option>
            <mat-option class="company-picker" *ngFor="let customerCompany of customerCompanyOptions"
              [value]="customerCompany" [attr.data-cy]="customerCompany.name">
              <span matTooltipPosition="above" matTooltipClass="multiline-tooltip"
                [matTooltip]="getTooltipCompany(customerCompany)">
                {{ customerCompany.name }}
              </span>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{'ORDERS.RECEIVING_PLACE' | translate}}</mat-label>
          <mat-select formControlName="customerCompanyBranch" required [attr.data-cy]="'customerBranch'"
            (ngModelChange)="handleCCBranchChange($event)">
            <mat-option class="company-picker" *ngFor="let branch of customerCompanyBranches; let i=index"
              [value]="branch.uuid" [attr.data-cy]="branch.name">
              <span matTooltipPosition="above" matTooltipClass="multiline-tooltip" [matTooltip]="getTooltip(branch)">
                {{ branch.name }}
              </span>
            </mat-option>
            <mat-option value="other" [attr.data-cy]="'otherBranch'">
              {{ "ORDERS.CUSTOMER_COMPANY_BRANCH.OTHER" | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-receiving">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.ACCEPTED_BY_SALES' | translate}}</mat-label>
          <mat-select formControlName="salesAccepted" required [attr.data-cy]="'acceptedBySales'">
            <mat-option [value]="true" [attr.data-cy]="'YES'">{{'SHARED.YES' | translate}}</mat-option>
            <mat-option [value]="false" [attr.data-cy]="'NO'">{{'SHARED.NO' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-receiving">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.COMPANY_BRANCH' | translate}}</mat-label>
          <mat-select formControlName="companyBranchId" required [attr.data-cy]="'companyBranch'">
            <mat-option *ngFor="let branch of companyBranches" [value]="branch.uuid"
              [attr.data-cy]="branch.name.toUpperCase()">
              {{ branch.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-receiving">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.RECEIVING_TIME_FROM' | translate}}</mat-label>
          <mat-select formControlName="availableHoursFrom" required [attr.data-cy]="'availableHoursFrom'">
            <mat-option *ngFor="let time of startTimeOptionsReceiving; let timeFromIndex=index" [value]="time"
              [disabled]="availableHoursFromDisabled(i, timeFromIndex)" [attr.data-cy]="time">
              {{ time }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-receiving">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.RECEIVING_TIME_TO' | translate}}</mat-label>
          <mat-select formControlName="availableHoursTo" required [attr.data-cy]="'availableHoursTo'">
            <mat-option *ngFor="let time of endTimeOptionsReceiving; let timeToIndex=index" [value]="time"
              [disabled]="availableHoursToDisabled(i, timeToIndex)" [attr.data-cy]="time">
              {{ time }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="input-wrapper-order-receiving">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.SUGGESTED_DATE_RECEIVING' | translate}}</mat-label>
          <input matInput [matDatepicker]="suggestedDate" formControlName="suggestedDate" [min]="todayDate" readonly>
          <mat-datepicker-toggle *ngIf="mode !== 'view'" matSuffix [for]="suggestedDate"
            [attr.data-cy]="'suggestedDate'"></mat-datepicker-toggle>
          <mat-datepicker #suggestedDate></mat-datepicker>
        </mat-form-field>
        <button mat-icon-button class="clear-date" *ngIf="mode !== 'view'" (click)="clearStartDate($event)">
          <mat-icon>clear</mat-icon>
        </button>
      </div>

      <div class="input-wrapper-order-receiving">
        <mat-form-field appearance="outline" fxFlex>
          <mat-label>{{'ORDERS.REFERENCE_NUMBER' | translate}}</mat-label>
          <input formControlName="referenceNumber" matInput [attr.data-cy]="'referenceNumber'">
        </mat-form-field>
      </div>

      <div *ngIf="orderReceivingForm.get('customerCompanyBranch').value" formGroupName="customerCompanyBranchOther"
        class="full-width-wrapper-horizontal">
        <div class="full-width-wrapper-horizontal">
          <h3> {{'ORDERS.RECEIVING_PLACE' | translate}}</h3>
        </div>
        <div></div>
        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.NAME' | translate}}</mat-label>
            <input formControlName="name" matInput required [attr.data-cy]="'otherBranchName'">
          </mat-form-field>
        </div>
        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.STREET' | translate}}</mat-label>
            <input formControlName="street" matInput required [attr.data-cy]="'otherBranchStreet'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.POSTAL_CODE' | translate}}</mat-label>
            <input formControlName="postalCode" matInput required [attr.data-cy]="'otherBranchPostalCode'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CITY' | translate}}</mat-label>
            <input formControlName="city" matInput required [attr.data-cy]="'otherBranchCity'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.COUNTRY' | translate}}</mat-label>
            <input formControlName="country" matInput required [attr.data-cy]="'otherBranchCountry'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.CONTACT' | translate}}</mat-label>
            <input formControlName="contact" matInput [attr.data-cy]="'otherBranchContact'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.CUSTOMER_COMPANY_BRANCH.PHONE' | translate}}</mat-label>
            <input formControlName="phone" matInput required [attr.data-cy]="'otherBranchPhone'">
          </mat-form-field>
        </div>

        <div  class="full-width-wrapper-vertical">
          <app-email-chips-input [mode]="mode" [emailControl]="orderReceivingForm.get('customerCompanyBranchOther').controls['email']">
          </app-email-chips-input>
        </div>

        <div class="full-width-wrapper-vertical">
          <h3> {{'ORDERS.CUSTOMER_COMPANY_BRANCH.CLIENT_COMMENT' | translate}}</h3>
          <mat-form-field appearance="outline" fxFlex="auto">
            <textarea rows="4" formControlName="note" matInput [attr.data-cy]="'otherBranchNote'"></textarea>
          </mat-form-field>
        </div>
      </div>

      <div class="full-width-wrapper-vertical">
        <h3> {{'ORDERS.BDO' | translate}}</h3>

        <div class="full-width-wrapper-horizontal">
          <div class="input-wrapper-order-receiving-reverse bdo-wrapper-input">
            <mat-form-field appearance="outline" fxFlex>
              <mat-label>{{'ORDERS.BDO_NUMBER' | translate}}</mat-label>
              <input formControlName="bdo" type="text" matInput [attr.data-cy]="'bdoNumber'"
                [required]="!orderReceivingForm.get('bdoFree').value && !orderReceivingForm.get('bdoPeopleCollection').value">
            </mat-form-field>
          </div>
          <div class="input-wrapper-order-receiving-reverse bdo-checkbox-wrapper bdo-wrapper">
            <div class="or-text">{{'SHARED.OR' | translate}}</div>
            <mat-checkbox class="bdo-required" formControlName="bdoFree" [attr.data-cy]="'bdoCheckbox'">
              {{'ORDERS.NO_BDO_CHECKBOX' | translate}}
            </mat-checkbox>
          </div>
          <div class="input-wrapper-order-receiving-reverse bdo-checkbox-wrapper bdo-wrapper">
            <div class="or-text">{{'SHARED.OR' | translate}}</div>
            <mat-checkbox class="bdo-required" formControlName="bdoPeopleCollection"
              [attr.data-cy]="'bdoPeopleCollection'">
              {{'ORDERS.BDO_PEOPLE_COLLECTION' | translate}}
            </mat-checkbox>
          </div>
        </div>
      </div>




      <div class="full-width-wrapper-vertical">
        <app-items-list-picker-form [mode]="mode" [required]="true"
          [formControl]="orderReceivingForm.controls['orderData']">
        </app-items-list-picker-form>
      </div>

      <div *ngIf="user.userType === userTypes.Logistician" class="full-width-wrapper-vertical">
        <app-tags-autocomplete [mode]="mode" [tagsControl]="orderReceivingForm.controls['tags']">
        </app-tags-autocomplete>
      </div>

      <div class="full-width-wrapper-vertical">
        <h3> {{'ORDERS.INTERNAL_COMMENT' | translate}} </h3>
        <mat-form-field appearance="outline" fxFlex="auto">
          <textarea rows="4" formControlName="comment" matInput [attr.data-cy]="'comment'"></textarea>
        </mat-form-field>
      </div>

      <div class="full-width-wrapper-vertical">
        <mat-checkbox formControlName="emailOrderReminder" [attr.data-cy]="'emailOrderReminder'">
          {{'ORDERS.EMAIL_ORDER_REMINDER' | translate}}
        </mat-checkbox>
      </div>


      <div class="full-width-wrapper-vertical">
        <mat-checkbox formControlName="reinvoice" (change)="reinvoiceChange($event.checked)"
          [attr.data-cy]="'reinvoice'">
          {{'ORDERS.REINVOICE_CHECKBOX' | translate}}
        </mat-checkbox>
      </div>

      <div class="full-width-wrapper-horizontal reinvoice">
        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.REINVOICE_VALUE' | translate}}</mat-label>
            <input formControlName="price" matInput [attr.data-cy]="'price'">
          </mat-form-field>
        </div>

        <div class="input-wrapper-order-receiving-reverse">
          <mat-form-field appearance="outline" fxFlex>
            <mat-label>{{'ORDERS.REINVOICE_NUMBER' | translate}}</mat-label>
            <input formControlName="reinvoiceNumber" matInput [attr.data-cy]="'reinvoiceNumber'">
          </mat-form-field>
        </div>
      </div>

      <div class="full-width-wrapper-vertical">
        <h3 class="m-0"> {{'ORDERS.INTERNAL_COMMENT_FV_ISSUER' | translate}}</h3>
        <mat-form-field appearance="outline" fxFlex="auto">
          <textarea formControlName="invoiceComment" matInput [attr.data-cy]="'invoiceComment'"></textarea>
        </mat-form-field>
      </div>

      <div class="full-width-wrapper-vertical" *ngIf="files.length > 0 || mode !== 'view'">
        <h3> {{'ORDERS.FILES' | translate}} </h3>
        <mat-error class="mb-12" *ngIf="sizeError">{{'SHARED.MESSAGES.FILE_TOO_BIG' | translate}}</mat-error>
        <mat-error class="mb-12" *ngIf="typeError">{{'SHARED.MESSAGES.FILE_WRONG_FORMAT' | translate}}</mat-error>
        <div class="file-upload-wrapper">
          <div class="file-wrapper" *ngFor="let file of files">
            <img (click)="openImage(file.url)" *ngIf="file.type !== 'application/pdf'" [src]="file.url"
              class="cursorIcon">
            <img (click)="openImage(file.url)" *ngIf="file.type === 'application/pdf'" class="pdf-icon cursorIcon"
              src="assets/images/pdf.png">
            <div *ngIf="file.type === 'application/pdf'" class="file-title">{{file.name}}</div>
            <mat-icon *ngIf="mode !== 'view'" class="file-wrapper__delete" (click)="deleteFile(file.fileId)">
              close
            </mat-icon>
          </div>
          <div class="file-wrapper" *ngFor="let file of newFiles; index as i">
            <img *ngIf="file.type !== 'application/pdf'" [src]="file.data">
            <img class="pdf-icon" *ngIf="file.type === 'application/pdf'" src="assets/images/pdf.png">
            <div class="file-title" *ngIf="file.type === 'application/pdf'">{{file.name}}</div>
            <mat-icon *ngIf="mode !== 'view'" class="file-wrapper__delete" (click)="deleteFile(i)">
              close
            </mat-icon>
          </div>
          <file-drop *ngIf="mode !== 'view'" class="file-wrapper add-file" (click)="openFile()"
            (onFileDrop)="dropFile($event)">
            <mat-icon>cloud_upload</mat-icon>
            {{'ORDERS.ADD_FILE' | translate}}
            <input #fileInput class="file-wrapper__input" id="file-input" type="file"
              (change)="addFile($event); fileInput.value = null;" />
          </file-drop>
        </div>
      </div>

      <div class="full-width-wrapper-vertical">
        <h3> {{'ORDERS.STATUS' | translate}} </h3>
        <mat-form-field appearance="outline" fxFlex="auto">
          <mat-label>{{'ORDERS.STATUS' | translate}}</mat-label>
          <mat-select formControlName="status">
            <mat-option value="new">{{'ORDERS.STATUSES.NEW' | translate}}</mat-option>
            <mat-option value="read">{{'ORDERS.STATUSES.READ' | translate}}</mat-option>
            <mat-option value="planned">{{'ORDERS.STATUSES.PLANNED' | translate}}</mat-option>
            <mat-option value="cancelled">{{'ORDERS.STATUSES.CANCELLED' | translate}}</mat-option>
            <mat-option value="completed">{{'ORDERS.STATUSES.COMPLETED' | translate}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>

    <div *ngIf="mode === 'view' && order">
      <app-order-details-form [orderData]="order"></app-order-details-form>
    </div>
  </div>

  <div mat-dialog-actions class="m-0 p-16" fxLayout="row" fxLayoutAlign="end center">
    <button *ngIf="mode !== 'add' && mode !== 'clone' && mode !== 'edit' && canGenerateTask" mat-button class="mr-8"
      [attr.data-cy]="'generateTask'" (click)="generateTask()">
      {{'ORDERS.GENERATE_TASK' | translate}}
    </button>

    <button *ngIf="mode !== 'add' && mode !== 'clone' && canViewTask" mat-button class="mr-8"
      [attr.data-cy]="'viewTask'" (click)="viewTask()">
      {{'ORDERS.VIEW_TASK' | translate}}
    </button>

    <button *ngIf="mode === 'edit' && canCancelOrder" mat-button class="mr-8" [attr.data-cy]="'changeOrderStatus'"
      (click)="changeOrderStatus(orderStatuses.Cancelled)">
      {{'ORDERS.CANCEL_ORDER' | translate}}
    </button>

    <button *ngIf="mode === 'edit'" mat-button class="mr-8" [attr.data-cy]="'deleteOrder'" (click)="deleteOrder()">
      {{'ORDERS.DELETE' | translate}}
    </button>

    <button *ngIf="mode === 'view' && user.userType === userTypes.Logistician" mat-button class="mr-8"
      [attr.data-cy]="'showhistory'" (click)="showHistory()">
      {{'ORDERS.SHOW_HISTORY' | translate}}
    </button>
    <button *ngIf="mode === 'view'" mat-button class="mr-8" [attr.data-cy]="'cloneOrder'" (click)="enableCloneMode()">
      {{'ORDERS.CLONE' | translate}}
    </button>
    <button *ngIf="mode === 'view' && canUserEdit" mat-button class="mr-8" [attr.data-cy]="'editOrder'"
      (click)="enableEditMode()">
      {{'ORDERS.EDIT' | translate}}
    </button>
    <button *ngIf="mode === 'edit' || mode === 'add' || mode === 'clone' || !canUserEdit" mat-button class="save-button"
      [attr.data-cy]="'saveOrder'" (click)="submitOrder()" [disabled]="isLoading">
      <span *ngIf="!isLoading"> {{'ORDERS.SAVE' | translate}}</span>
      <span *ngIf="isLoading" class="loading-spinner-wrapper">
        <mat-progress-spinner mode="indeterminate" [strokeWidth]="2" [diameter]="20">
        </mat-progress-spinner>
      </span>
    </button>
  </div>
</div>