import {Component, ElementRef, HostListener, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from '@angular/material';
import {TranslateService} from '@ngx-translate/core';
import {FuseTranslationLoaderService} from '../../../../../../@fuse/services/translation-loader.service';
import {OrderFile, OrderReceiving, OrderSatuses} from '../../../../../shared/models/orders.model';
import {OrdersService} from '../../../../../services/orders.service';
import {locale as polish} from '../../../../../translations/pl';
import {locale as english} from '../../../../../translations/en';
import {ConfirmModalComponent} from '../../../../shared/components/confirm-modal/confirm-modal.component';
import {AuthService} from '../../../../../services/auth.service';
import {RoleTypes, User, UserTypes} from '../../../../../shared/models/users.model';
import {catchError, map, mergeMap, switchMap} from 'rxjs/operators';
import {RequestParams} from '../../../../../shared/models/list.model';
import {NEVER, of, throwError, zip} from 'rxjs';
import {UsersService} from '../../../../../services/users.service';
import * as moment from 'moment';
import {UploadEvent} from 'ngx-file-drop';
import {environment} from '../../../../../../environments/environment';
import {CompanyBranch} from '../../../../../shared/models/company.model';
import {ValidateItemsList} from '../../../../../shared/validators/itemsList.validator';
import {FilteredCompany} from '../../../../../shared/models/filtered-items.model';
import {FilteredListsService} from '../../../../../services/filtered-lists.service';
import {OrderTypes} from '../../../../../shared/consts/orders';
import {Company} from '../../../../../shared/models/companies.model';
import {mapOrderDataApiValues, mapOrderDataToUuid} from '../../../../../shared/utils/tasks-map';
import {
  TaskReceivingFormComponent
} from '../../../../tasks/components/task-forms/task-receiving-form/task-receiving-form.component';
import {HistoryDialogComponent} from '../../../../shared/components/history-dialog/history-dialog.component';
import {ItemsOrigins} from '../../../../../shared/models/history.model';
import {EndTimeOptionsReceiving, StartTimeOptionsReceiving} from '../../../../../shared/consts/tasks';
import {Mode} from '../../../../../shared/consts/mode';
import {CompanyEditorComponent} from '../../../../companies/components/company-editor/company-editor.component';
import {CustomerCompanyBranch} from '../../../../../shared/models/company-branches.model';
import {CompanyBranchesService} from '../../../../../services/company-branches.service';
import {CompaniesService} from '../../../../../services/companies.service';
import {markFormGroupTouched} from 'app/shared/utils/markFormGroupAsTouched';

@Component({
  selector: 'app-order-receiving-form',
  templateUrl: './order-receiving-form.component.html',
  styleUrls: ['./order-receiving-form.component.scss']
})
export class OrderReceivingFormComponent implements OnInit {
  @ViewChild('companySelect') companySelect;
  @ViewChild('fileInput') fileInput: ElementRef;
  public orderReceivingForm: FormGroup;
  public orderUuid: OrderReceiving['uuid'];
  public order: OrderReceiving;
  public orderStatuses = OrderSatuses;
  public files: OrderFile[] = [];
  public newFiles: OrderFile[] = [];
  public user: User;
  public userTypes = UserTypes;
  public roleTypes = RoleTypes;
  public canUserEdit: boolean = false;
  public canCancelOrder: boolean = false;
  public canGenerateTask: boolean = false;
  public canViewTask: boolean = false;
  public customerCompanyOptions: FilteredCompany[] = [];
  public companyBranches: CompanyBranch[] = [];
  public customerCompanyBranches: CustomerCompanyBranch[] = [];
  public mode: Mode;
  public isLoading = false;
  public sizeError = false;
  public typeError = false;
  public searchCustomerCompany: FormControl;
  public startTimeOptionsReceiving = StartTimeOptionsReceiving;
  public endTimeOptionsReceiving = EndTimeOptionsReceiving;
  public todayDate: Date;
  private bdo: string;
  private filterParams: RequestParams = {
    page: 1,
    limit: 20,
    sort: 'name',
    order: 'asc'
  };

  constructor(
    public orderEditorDialogRef: MatDialogRef<OrderReceivingFormComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private matDialog: MatDialog,
    private authService: AuthService,
    private ordersService: OrdersService,
    private filteredListsService: FilteredListsService,
    private usersService: UsersService,
    private companyBranchesService: CompanyBranchesService,
    private companiesService: CompaniesService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this.mode = data.mode;
    this.orderUuid = data.orderUuid;
    this.user = this.authService.user.user;

    this._fuseTranslationLoaderService.loadTranslations(polish, english);
  }

  ngOnInit() {
    this.createForm();
    this.createSearchFilters();

    if (this.mode === 'edit' || this.mode === 'view' || this.mode === 'clone') {
      this.ordersService
        .getOrder(this.orderUuid)
        .pipe(
          map(res => {
            this.order = res;
            this.files = res.files;

            this.canCancelOrder =
              this.order &&
              this.order.status !== this.orderStatuses.Cancelled &&
              (this.user.userType === this.userTypes.Sales ||
                this.user.userType === this.userTypes.Logistician ||
                this.user.uuid === this.order.createdBy.uuid);

            this.canUserEdit =
              this.order &&
              !this.order.taskNumber &&
              (this.user.userType === this.userTypes.Sales ||
                this.user.userType === this.userTypes.Logistician ||
                this.user.uuid === this.order.createdBy.uuid);

            this.canGenerateTask =
              this.order &&
              this.order.status !== this.orderStatuses.Cancelled &&
              !this.order.taskNumber &&
              this.user.userType === this.userTypes.Logistician;

            this.canViewTask =
              this.order &&
              this.order.taskNumber &&
              this.user.userType === this.userTypes.Logistician;
          }),
          switchMap(() => {
            return zip(
              this.filteredListsService.getCompaniesList(this.filterParams),
              this.usersService.getCompanyBranchList(),
              this.companiesService.getCompany(this.order.customerCompany.uuid)
            );
          })
        )
        .subscribe(([companiesRes, branchesRes, company]) => {
          this.customerCompanyOptions = companiesRes.customerCompanies;
          this.companyBranches = branchesRes.branches;
          this.bdo = company.bdo;

          this.createForm(this.order);
          this.checkCustomerCompaniesMissing();
          this.getCustomerCompanyBranches(this.order.customerCompany.uuid);
        });
    } else {
      this.todayDate = new Date();

      this.filteredListsService
        .getCompaniesList(this.filterParams)
        .subscribe(
          res => (this.customerCompanyOptions = res.customerCompanies)
        );
      this.usersService
        .getCompanyBranchList()
        .subscribe(res => (this.companyBranches = res.branches));
    }
  }



  public submitOrder() {
    this.isLoading = true;
    markFormGroupTouched(this.orderReceivingForm);
    if (this.orderReceivingForm.invalid) {
      this.isLoading = false;
      return;
    }
    const newOrder = JSON.parse(JSON.stringify(this.orderReceivingForm.getRawValue()));

    if (newOrder.suggestedDate) {
      newOrder.suggestedDate = moment(newOrder.suggestedDate).format(
        'YYYY-MM-DD'
      );
    }

    newOrder.bdo = newOrder.bdo ? newOrder.bdo.toString() : null;
    newOrder.bdoFree = Boolean(newOrder.bdoFree && !newOrder.bdo);
    newOrder.bdoPeopleCollection = Boolean(newOrder.bdoPeopleCollection && !newOrder.bdo);
    newOrder.customerCompanyId = newOrder.customerCompany
      ? newOrder.customerCompany.uuid
      : null;
    const CCBranchId = newOrder.customerCompanyBranch;
    newOrder.customerCompanyBranch = {
      ...newOrder.customerCompanyBranchOther,
      uuid: CCBranchId !== 'other' ? CCBranchId : null
    };

    newOrder.customerCompanyBranch.email = newOrder.customerCompanyBranch.email || null;
    newOrder.price = Number(String(newOrder.price).replace(',', '.')) || null;
    delete newOrder.customerCompanyBranchOther;
    delete newOrder.customerCompany;

    newOrder.orderData = mapOrderDataApiValues(newOrder.orderData);

    if (this.mode === 'add' || this.mode === 'clone') {
      this.ordersService
        .createOrder(newOrder)
        .pipe(
          catchError(() => {
            this.isLoading = false;
            this.snackBar.open(
              this.translateService.instant('ORDERS.MESSAGES.CREATE_ERROR'),
              '',
              { duration: 5000 }
            );
            return NEVER;
          }),
          mergeMap(res => {
            if (this.newFiles) {
              this.addFiles(res.uuid);
            }
            return of({});
          })
        )
        .subscribe(filesSuccess => {
          this.orderEditorDialogRef.close();
          this.orderEditorDialogRef.afterClosed().subscribe(() => {
            this.ordersService.onEditEvent.emit();
          });
          if (filesSuccess) {
            this.snackBar.open(
              this.translateService.instant('ORDERS.MESSAGES.CREATE_SUCCESS'),
              '',
              { duration: 5000 }
            );
          } else {
            this.snackBar.open(
              this.translateService.instant(
                'ORDERS.MESSAGES.CREATE_SUCCESS_FILES_ERROR'
              ),
              '',
              { duration: 5000 }
            );
          }
        });
    }
    else if (this.mode === 'view' && !this.canUserEdit) {
      this.ordersService.updateOrderDate(this.orderUuid, newOrder.suggestedDate).subscribe(
        () => {
          this.orderEditorDialogRef.close();
          this.orderEditorDialogRef.afterClosed().subscribe(() => {
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant('ORDERS.MESSAGES.UPDATE_SUCCESS'),
            '',
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant('ORDERS.MESSAGES.UPDATE_ERROR'),
            '',
            { duration: 5000 }
          );
        }
      );
    }
    else {
      this.ordersService.updateOrder(this.orderUuid, newOrder).subscribe(
        () => {
          this.orderEditorDialogRef.close();
          this.orderEditorDialogRef.afterClosed().subscribe(() => {
            this.ordersService.onEditEvent.emit();
          });
          this.snackBar.open(
            this.translateService.instant('ORDERS.MESSAGES.UPDATE_SUCCESS'),
            '',
            { duration: 5000 }
          );
        },
        () => {
          this.isLoading = false;
          this.snackBar.open(
            this.translateService.instant('ORDERS.MESSAGES.UPDATE_ERROR'),
            '',
            { duration: 5000 }
          );
        }
      );
    }
  }

  public openImage(url) {
    window.open(url);
  }

  public deleteOrder() {
    let changeStatusDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });

    changeStatusDialogRef.componentInstance.confirmMessage = this.translateService.instant(
      'ORDERS.MESSAGES.DELETE_QUESTION'
    );
    changeStatusDialogRef.componentInstance.titleMessage = this.translateService.instant(
      'ORDERS.MESSAGES.DELETE_ORDER_TITLE'
    );
    changeStatusDialogRef.componentInstance.confirmButton = this.translateService.instant(
      'ORDERS.DELETE'
    );

    changeStatusDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.ordersService.deleteOrder(this.orderUuid).subscribe(
          () => {
            this.orderEditorDialogRef.close();
            this.orderEditorDialogRef.afterClosed().subscribe(() => {
              this.ordersService.onEditEvent.emit();
            });
            this.snackBar.open(
              this.translateService.instant('ORDERS.MESSAGES.DELETE_SUCCESS'),
              '',
              { duration: 5000 }
            );
          },
          () => {
            this.snackBar.open(
              this.translateService.instant('ORDERS.MESSAGES.DELETE_ERROR'),
              '',
              { duration: 5000 }
            );
          }
        );
      }
    });
  }

  public generateTask() {
    if (this.order.companyBranch !== null && this.order.salesAccepted) {
      this.orderEditorDialogRef.close();
      this.matDialog.open(TaskReceivingFormComponent, {
        panelClass: 'task-editor-dialog',
        autoFocus: false,
        disableClose: true,
        data: {
          mode: 'add',
          orders: [this.order]
        }
      });
    }
    else {
      this.snackBar.open(
        this.translateService.instant('ORDERS.MESSAGES.GENERATE_ERROR'),
        '',
        { duration: 5000 }
      );
    }
  }

  public viewTask() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(TaskReceivingFormComponent, {
      panelClass: 'task-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'view',
        taskNumber: this.order.taskNumber
      }
    });
  }

  public changeOrderStatus(status: string) {
    let changeStatusDialogRef = this.matDialog.open(ConfirmModalComponent, {
      disableClose: false,
      autoFocus: false
    });

    if (status === this.orderStatuses.Planned) {
      changeStatusDialogRef.componentInstance.confirmMessage = this.translateService.instant(
        'ORDERS.MESSAGES.PLAN_QUESTION'
      );
      changeStatusDialogRef.componentInstance.titleMessage = this.translateService.instant(
        'ORDERS.MESSAGES.PLAN_ORDER_TITLE'
      );
      changeStatusDialogRef.componentInstance.confirmButton = this.translateService.instant(
        'ORDERS.PLAN_ORDER'
      );

      changeStatusDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ordersService
            .changeOrderStatus(this.orderUuid, this.orderStatuses.Planned)
            .subscribe(
              () => {
                this.snackBar.open(
                  this.translateService.instant('ORDERS.MESSAGES.PLAN_SUCCESS'),
                  '',
                  { duration: 5000 }
                );
                this.order.status = this.orderStatuses.Planned;
                this.ordersService.onEditEvent.emit();
              },
              () => {
                this.snackBar.open(
                  this.translateService.instant('ORDERS.MESSAGES.PLAN_ERROR'),
                  '',
                  { duration: 5000 }
                );
              }
            );
        }
      });
    } else {
      changeStatusDialogRef.componentInstance.confirmMessage = this.translateService.instant(
        'ORDERS.MESSAGES.CANCEL_QUESTION'
      );
      changeStatusDialogRef.componentInstance.titleMessage = this.translateService.instant(
        'ORDERS.MESSAGES.CANCEL_ORDER_TITLE'
      );
      changeStatusDialogRef.componentInstance.confirmButton = this.translateService.instant(
        'ORDERS.CANCEL_ORDER'
      );

      changeStatusDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ordersService
            .changeOrderStatus(this.orderUuid, this.orderStatuses.Cancelled)
            .subscribe(
              () => {
                this.snackBar.open(
                  this.translateService.instant(
                    'ORDERS.MESSAGES.CANCEL_SUCCESS'
                  ),
                  '',
                  { duration: 5000 }
                );
                this.order.status = this.orderStatuses.Cancelled;
                this.canCancelOrder = false;
                this.ordersService.onEditEvent.emit();
                this.orderEditorDialogRef.close();
              },
              () => {
                this.snackBar.open(
                  this.translateService.instant('ORDERS.MESSAGES.CANCEL_ERROR'),
                  '',
                  { duration: 5000 }
                );
              }
            );
        }
      });
    }
  }

  public async setCustomerCompany(customerCompany: Company) {
    if (customerCompany && customerCompany.uuid) {
      await this.getCustomerCompanyBranches(customerCompany.uuid);
    }
    const ccMainBranch = this.customerCompanyBranches[0];
    this.orderReceivingForm.get('customerCompanyBranch').enable();
    this.orderReceivingForm.patchValue({
      customerCompany: customerCompany,
      customerCompanyBranch: ccMainBranch ? ccMainBranch.uuid || 'other' : null,
      customerCompanyBranchOther: {
        street: (ccMainBranch && ccMainBranch.street) || null,
        postalCode: (ccMainBranch && ccMainBranch.postalCode) || null,
        city: (ccMainBranch && ccMainBranch.city) || null,
        country: (ccMainBranch && ccMainBranch.country) || 'Polska',
        contact: (ccMainBranch && ccMainBranch.contact) || null,
        email: (ccMainBranch && ccMainBranch.email) || null,
        phone: (ccMainBranch && ccMainBranch.phone) || null,
        note: (ccMainBranch && ccMainBranch.note) || null,
        mainBranch: false
      }
    });
    if (customerCompany && customerCompany.trusted) {
      this.orderReceivingForm.get('salesAccepted').patchValue(true);
    } else {
      this.orderReceivingForm.get('salesAccepted').patchValue(false);
    }
  }

  public compareObjectsByUuid(val1, val2): boolean {
    return val1 && val2 && val1.uuid && val2.uuid && val1.uuid === val2.uuid;
  }

  public getTitle(): string {
    switch (this.mode) {
      case 'add':
      case 'clone':
        return this.translateService.instant('ORDERS.TITLE_ADD');
      case 'edit':
        return this.translateService.instant('ORDERS.TITLE_EDIT');
      case 'view':
        return this.translateService.instant('ORDERS.TITLE_VIEW');
      default:
        return;
    }
  }

  public showHistory() {
    this.matDialog.open(HistoryDialogComponent, {
      panelClass: 'history-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        item: this.order,
        itemOrigin: ItemsOrigins.Order,
        itemId: this.orderUuid,
        itemNumber: this.order.orderNumber
      }
    });
  }

  public enableEditMode() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(OrderReceivingFormComponent, {
      panelClass: 'order-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        orderUuid: this.orderUuid
      }
    });
  }

  public enableCloneMode() {
    this.orderEditorDialogRef.close();
    this.matDialog.open(OrderReceivingFormComponent, {
      panelClass: 'order-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'clone',
        orderUuid: this.orderUuid
      }
    });
  }

  // FILES
  public openFile() {
    this.fileInput.nativeElement.click();
  }

  public dropFile(event: UploadEvent) {
    event.files[0].fileEntry['file'](fileData => {
      this.uploadFile(fileData);
    });
  }

  public addFile(event) {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadFile(file);
    }
  }

  public deleteFile(event) {
    if (this.mode === 'add') {
      this.newFiles.splice(event, 1);
    } else {
      let deleteDialogRef = this.matDialog.open(ConfirmModalComponent, {
        disableClose: false,
        autoFocus: false
      });

      deleteDialogRef.componentInstance.confirmMessage = this.translateService.instant(
        'ORDERS.MESSAGES.DELETE_FILE_QUESTION'
      );
      deleteDialogRef.componentInstance.titleMessage = this.translateService.instant(
        'ORDERS.MESSAGES.DELETE_FILE_TITLE'
      );
      deleteDialogRef.componentInstance.confirmButton = this.translateService.instant(
        'ORDERS.DELETE'
      );

      deleteDialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.ordersService.deleteFile(this.orderUuid, event).subscribe(
            () => {
              deleteDialogRef.close();
              this.snackBar.open(
                this.translateService.instant(
                  'ORDERS.MESSAGES.DELETE_FILE_SUCCESS'
                ),
                '',
                { duration: 5000 }
              );
              this.files = this.files.filter(item => item.fileId !== event);
            },
            () => {
              deleteDialogRef.close();
              this.snackBar.open(
                this.translateService.instant(
                  'ORDERS.MESSAGES.DELETE_FILE_ERROR'
                ),
                '',
                { duration: 5000 }
              );
            }
          );
        }
      });
    }
  }

  public addCustomerCompany() {
    let editorDialogRef = this.matDialog.open(CompanyEditorComponent, {
      panelClass: 'company-editor-dialog',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'add'
      }
    });

    editorDialogRef.componentInstance.onAddCompany.subscribe(res => {
      this.customerCompanyOptions.push(res);
      this.setCustomerCompany(res);
      this.companySelect.close();
    });
  }

  public openEmailClient() {
    const orderData = this.orderReceivingForm.getRawValue();
    if (orderData.customerCompanyBranchOther.email) {
      return `mailto:${orderData.customerCompanyBranchOther.email}`;
    }
  }

  public canSendEmail() {
    return this.mode === 'view' && this.orderReceivingForm.getRawValue().customerCompanyBranchOther.email;
  }

  private uploadFile(file) {
    let fileItem;
    const allowedExtentions = ['png', 'jpg', 'jpeg', 'gif', 'bmp', 'pdf'];
    const extention = file.name
      .split('.')
      .pop()
      .toLowerCase();

    if (!allowedExtentions.includes(extention)) {
      this.sizeError = false;
      return (this.typeError = true);
    } else if (file.size > 5242880) {
      this.typeError = false;
      return (this.sizeError = true);
    } else {
      this.sizeError = false;
      this.typeError = false;

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        fileItem = { data: reader.result, name: file.name, type: file.type };

        if (this.mode === 'add') {
          this.newFiles.push(fileItem);
        } else {
          this.ordersService.addFile(this.orderUuid, fileItem).subscribe(
            res => {
              res.url = `${environment.apiUrl
                }/${this.authService.getUserType()}/orders/${this.orderUuid
                }/files/${res.fileId}`;
              this.files.push(res);
              this.snackBar.open(
                this.translateService.instant(
                  'ORDERS.MESSAGES.ADD_FILE_SUCCESS'
                ),
                '',
                { duration: 5000 }
              );
            },
            () => {
              this.snackBar.open(
                this.translateService.instant('ORDERS.MESSAGES.ADD_FILE_ERROR'),
                '',
                { duration: 5000 }
              );
            }
          );
        }
      };
    }
  }

  private addFiles(orderId) {
    let filesSuccess = true;
    this.newFiles.forEach(file => {
      return this.ordersService
        .addFile(orderId, file)
        .pipe(
          catchError(err => {
            this.isLoading = false;
            filesSuccess = false;
            return throwError(err);
          })
        )
        .subscribe(() => filesSuccess);
    });
  }

  private async getCustomerCompanyBranches(customerCompanyId: string) {
    try {
      const res = await this.companyBranchesService
        .getCompanyBranchesList({ limit: 500, page: 0 }, customerCompanyId).toPromise();
      this.customerCompanyBranches = res.branches;
      return true;
    } catch (e) {
      return false;
    }
  }


  public availableHoursFromDisabled = (i, timeIndex) => {
    return this.orderReceivingForm.get('availableHoursTo').value &&
      this.startTimeOptionsReceiving.includes(this.orderReceivingForm.get('availableHoursTo').value) &&
      timeIndex + 1 > this.startTimeOptionsReceiving.indexOf(this.orderReceivingForm.get('availableHoursTo').value);
  }

  public availableHoursToDisabled = (i, timeIndex) => {
    return this.orderReceivingForm.get('availableHoursFrom').value &&
      timeIndex < this.startTimeOptionsReceiving.indexOf(this.orderReceivingForm.get('availableHoursFrom').value);
  }

  private createForm(inputData: OrderReceiving = new OrderReceiving()) {
    inputData.orderData = mapOrderDataToUuid(inputData.orderData);
    this.orderReceivingForm = this.formBuilder.group({
      customerCompany: new FormControl(
        inputData.customerCompany || null,
        Validators.required
      ),
      salesAccepted: new FormControl(
        inputData.salesAccepted || false,
        Validators.required
      ),
      companyBranchId: new FormControl(
        inputData.companyBranch ? inputData.companyBranch.uuid : null,
        Validators.required
      ),
      customerCompanyBranch: new FormControl(
        inputData.customerCompanyBranch
          ? inputData.customerCompanyBranch.uuid
            ? inputData.customerCompanyBranch.uuid
            : 'other'
          : null,
        Validators.required
      ),
      orderData: new FormControl(inputData.orderData || [], ValidateItemsList),
      suggestedDate: new FormControl(
        this.mode === 'clone'
          ? null
          : inputData.suggestedDate
            ? new Date(inputData.suggestedDate)
            : null
      ),
      availableHoursFrom: new FormControl(inputData.availableHoursFrom || null),
      availableHoursTo: new FormControl(inputData.availableHoursTo || null),
      tags: new FormControl(inputData.tags || []),
      comment: new FormControl(this.mode === 'clone' ? null : inputData.comment || null),
      bdo: new FormControl(inputData.bdo || null, Validators.pattern('[0-9]+')),
      bdoFree: new FormControl(inputData.bdoFree && !inputData.bdo && this.mode !== 'add'),
      bdoPeopleCollection: new FormControl(inputData.bdoPeopleCollection && !inputData.bdo && this.mode !== 'add'),
      emailOrderReminder: new FormControl(this.mode === 'add' ? true : inputData.emailOrderReminder ),
      reinvoice: new FormControl((inputData.reinvoice) || false),
      price: new FormControl(inputData.price ? String(inputData.price).replace('.', ',') : ''),
      reinvoiceNumber: new FormControl((inputData.reinvoiceNumber) || ''),
      invoiceComment: new FormControl((inputData.invoiceComment) || ''),
      referenceNumber: new FormControl(
        this.mode === 'clone' ? null : inputData.referenceNumber || null
      ),
      orderType: new FormControl(inputData.orderType || OrderTypes.Receiving),
      status: new FormControl(this.mode === 'clone' ? 'new' : inputData.status || 'new'),
      customerCompanyBranchOther: new FormGroup({
        name: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.name) ||
          null,
          inputData.customerCompanyBranch && inputData.customerCompanyBranch.name ? Validators.required : null
        ),
        street: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.street) ||
          null,
          Validators.required
        ),
        postalCode: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.postalCode) ||
          null,
          Validators.required
        ),
        city: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.city) ||
          null,
          Validators.required
        ),
        country: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.country) ||
          'Polska',
          Validators.required
        ),
        contact: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.contact) ||
          null
        ),
        email: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.email) ||
          null
        ),
        phone: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.phone) ||
          null,
          Validators.required
        ),
        note: new FormControl(
          (inputData.customerCompanyBranch &&
            inputData.customerCompanyBranch.note) ||
          null
        ),
        mainBranch: new FormControl(false)
      })
    });
    if (this.mode === 'view') {
      this.orderReceivingForm.disable();
    }

    if (this.mode === 'view' && !this.canUserEdit) {
      this.orderReceivingForm.disable();
      this.orderReceivingForm.get('suggestedDate').enable();
    }

    if (this.mode === 'add' || this.mode === 'clone' || this.user.userType !== this.userTypes.Logistician) {
      this.orderReceivingForm.get('status').disable();
      this.orderReceivingForm.get('customerCompanyBranch').disable();
    }

    if (this.mode === 'clone' && this.orderReceivingForm.get('bdoFree').value || this.orderReceivingForm.get('bdo').value) {
      this.orderReceivingForm.get('bdo').disable();
    }

    if (this.mode === 'add' || this.mode === 'clone' || this.mode === 'edit') {
      this.orderReceivingForm.get('bdoFree').valueChanges.subscribe(bdoFree => {
        const pickedCustomerCompany = this.orderReceivingForm.get('customerCompany').value;
        const bdoPeopleCollection = this.orderReceivingForm.get('bdoPeopleCollection').value;
        if (bdoFree) {
          this.orderReceivingForm.get('bdoPeopleCollection').setValue(false);
          this.orderReceivingForm.get('bdo').clearValidators();
          this.orderReceivingForm.get('bdo').setValue('');
        } else if (!bdoFree && !bdoPeopleCollection && ((pickedCustomerCompany && pickedCustomerCompany.bdo) || (this.order && this.order.bdo))) {
          this.orderReceivingForm.get('bdo').setValidators(Validators.required);
          this.orderReceivingForm.get('bdo').setValue(pickedCustomerCompany.bdo || this.order.bdo);
        } else if (!bdoFree && !bdoPeopleCollection && (!pickedCustomerCompany || !pickedCustomerCompany.bdo && this.mode !== 'clone')) {
          this.orderReceivingForm.get('bdo').setValidators(Validators.required);
          this.orderReceivingForm.get('bdo').enable();
        } else {
          this.orderReceivingForm.get('bdo').setValue(this.bdo);
        }
      });
      this.orderReceivingForm.get('bdoPeopleCollection').valueChanges.subscribe(bdoPeopleCollection => {
        const bdoFree = this.orderReceivingForm.get('bdoFree').value;
        if (bdoPeopleCollection) {
          this.orderReceivingForm.get('bdoFree').setValue(false);
          this.orderReceivingForm.get('bdo').clearValidators();
          this.orderReceivingForm.get('bdo').setValue('');
          this.orderReceivingForm.get('bdo').disable();
        } else if (!bdoPeopleCollection && !bdoFree) {
          this.orderReceivingForm.get('bdo').setValidators(Validators.required);
          this.orderReceivingForm.get('bdo').enable();
        }
      });
    }

    if (this.mode === 'add' || this.mode === 'clone' || this.mode === 'edit') {
      this.orderReceivingForm.get('customerCompany').valueChanges.subscribe(pickedCustomerCompany => {
        const bdoFree = this.orderReceivingForm.get('bdoFree').value;
        const bdoPeopleCollection = this.orderReceivingForm.get('bdoPeopleCollection').value;
        if (pickedCustomerCompany && pickedCustomerCompany.bdo && !bdoFree && !bdoPeopleCollection) {
          this.orderReceivingForm.get('bdo').setValue(pickedCustomerCompany.bdo);
          this.orderReceivingForm.get('bdo').disable();
        } else if (pickedCustomerCompany && !pickedCustomerCompany.bdo && !bdoFree && !bdoPeopleCollection) {
          this.orderReceivingForm.get('bdo').setValue('');
          this.orderReceivingForm.get('bdo').enable();
        } else if (pickedCustomerCompany && !pickedCustomerCompany.bdo && (bdoFree || bdoPeopleCollection)) {
          this.orderReceivingForm.get('bdo').setValue('');
          this.orderReceivingForm.get('bdo').disable();
        }
      });
    }

   if (this.orderReceivingForm.get('reinvoice').value === false) {
      this.orderReceivingForm.get('price').disable();
      this.orderReceivingForm.get('reinvoiceNumber').disable();
    }
  }

  private checkCustomerCompaniesMissing() {
    const chosenCustomerCompany = this.orderReceivingForm.get('customerCompany')
      .value;
    if (
      chosenCustomerCompany &&
      !this.customerCompanyOptions.find(
        item => item.uuid === chosenCustomerCompany.uuid
      )
    ) {
      this.customerCompanyOptions.push(chosenCustomerCompany);
    }
  }

  private createSearchFilters() {
    this.searchCustomerCompany = new FormControl('');
    this.searchCustomerCompany.valueChanges
      .debounceTime(300)
      .subscribe(value => {
        this.filteredListsService
          .getCompaniesList({ ...this.filterParams, ...{ search: value } })
          .subscribe(response => {
            this.customerCompanyOptions = response.customerCompanies;
            !value && this.checkCustomerCompaniesMissing();
          });
      });
  }

   public reinvoiceChange(reinvoiceChecked: boolean) {
    if (reinvoiceChecked) {
      this.orderReceivingForm.get('price').enable();
      this.orderReceivingForm.get('reinvoiceNumber').enable();
    } else {
      this.orderReceivingForm.get('price').setValue(null);
      this.orderReceivingForm.get('price').disable();
      this.orderReceivingForm.get('reinvoiceNumber').setValue('');
      this.orderReceivingForm.get('reinvoiceNumber').disable();
    }
  }

  public handleCCBranchChange(branchId: string) {
    const branch = this.customerCompanyBranches && this.customerCompanyBranches.find(
      (item: CustomerCompanyBranch) => item.uuid === branchId
    );
    this.orderReceivingForm.get('customerCompanyBranchOther').patchValue({
      name: (branch && branch.name) || null,
      street: (branch && branch.street) || null,
      postalCode: (branch && branch.postalCode) || null,
      city: (branch && branch.city) || null,
      country: (branch && branch.country) || 'Polska',
      contact: (branch && branch.contact) || null,
      email: (branch && branch.email) || null,
      phone: (branch && branch.phone) || null,
      note: (branch && branch.note) || null,
      mainBranch: false
    });
  }

  public getTooltip(branch: any) {
    return `${branch.name}
    ${branch.street}
    ${branch.postalCode} ${branch.city}`;
  }

  public getTooltipCompany(company: any) {
    if (company) {
      return `${company.name} 
      ${company.address.street}
      ${company.address.postalCode} ${company.address.city}`;
    }
  }

  public clearStartDate(e) {
    e.stopPropagation();
    this.orderReceivingForm.get('suggestedDate').reset();
  }

  private isCloseDialogOpen: boolean = false; // without this, you can open the dialog multiple times
  @HostListener('window:keyup.esc')
  closeDialog() {

    if (!this.orderReceivingForm.dirty) {
      this.orderEditorDialogRef.close();
      return;
    }

    if (this.isCloseDialogOpen === false) {
      const saveDialogRef = this.matDialog.open(ConfirmModalComponent, {
        disableClose: true,
        autoFocus: false
      });
      saveDialogRef.componentInstance.confirmMessage = this.translateService.instant('SHARED.MESSAGES.CLOSE_DIALOG_WITHOUT_SAVE');
      saveDialogRef.componentInstance.titleMessage = this.translateService.instant('SHARED.MESSAGES.USAVED_DATA');
      saveDialogRef.componentInstance.confirmButton = this.translateService.instant('SHARED.NO');
      saveDialogRef.componentInstance.skipButton = this.translateService.instant('SHARED.YES');

      this.isCloseDialogOpen = true;
      saveDialogRef.afterClosed().subscribe(result => {
        this.isCloseDialogOpen = false;
        if (!result) {
          this.orderEditorDialogRef.close();
        }
      });
    }
  }
}
